* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.res_home_first_section {
  height: 86vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  overflow-y: hidden;
  // background-color: rgba(0, 0, 0, 0.241);
  .overlay {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    // background-color: #18af1d2c;
    background: linear-gradient(
        90deg,
        rgba(25, 176, 31, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
    mix-blend-mode: multiply;
  }

  video {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  .first_sec_contents {
    // width: 53%;
    width: 80%;

    height: 100%;
    // background-color: red;
    padding-left: 10rem;
    padding-right: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #fff;

    p {
      font-size: 22px;
      font-weight: 400;
      font-family: "PoppinsThree";
    }

    h1 {
      font-size: 50px;
      text-transform: capitalize;
      margin-bottom: 0;
      font-weight: 800;

      // font-family: "GilroySix";
    }

    .search_input_wrapper {
      width: 100%;
      height: max-content;
      display: flex;
      gap: 1rem;
      margin-top: 1rem;

      .search_input {
        width: 65%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        border: 1px solid #fff;
        padding-left: 0.5rem;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.189);
        // overflow: scroll;

        .loc_icon {
          font-size: 24px;
          margin-top: 0.6rem;
        }

        input {
          font-size: 16px;
          padding: 0.7rem 0.5rem;
          width: 100%;
          border: none;
          outline: none;
          background: transparent;
          color: #fff;
        }
      }

      .search_button {
        width: 25%;
        height: 2.8rem;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        background-color: #19b01f;
        transition: 0.5s ease;

        &:hover {
          cursor: pointer;
          background-color: #fff;
          color: #19b01f;
        }
      }
    }
  }
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 839.98px) {
  .res_home_first_section {
    height: 30rem;
  
    video {
      height: 100%;
      width: auto;
    }
  
    .first_sec_contents {
      width: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      p {
        font-size: 18px;
        margin-bottom: 0.5rem;
      }

      h1 {
        font-size: 40px;
      }
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 840px) and (max-width: 1200px) {
  .res_home_first_section {
    height: 40rem;
  
    video {
      height: 100%;
      width: auto;
    }
  
    .first_sec_contents {
      width: 100%;
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }
}

