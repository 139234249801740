/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Inter', sans-serif !important; */
}

img,
video {
  user-select: none !important;
  pointer-events: none !important;
}

body {
  overflow-x: hidden;
}

/* #Whatsapp_icon {
  position: "fixed";
  bottom: "2.5rem";
  right: "2.5rem";
  color: "#fff";
  height: "62px";
  width: "62px";
  border-radius: "50%";
  background-color: "#19B01F";
  display: "flex";
  align-items: "center";
  justify-content: "center";
  cursor: "pointer";
  z-index: "999";
}

@media screen and (max-width: 678px) {
  #Whatsapp_icon {
    position: "fixed";
    bottom: "0rem";
    right: "0rem";
    color: "#fff";
    height: "62px";
    width: "62px";
    border-radius: "50%";
    background-color: "#19B01F";
    display: "flex";
    align-items: "center";
    justify-content: "center";
    cursor: "pointer";
    z-index: "999";
  }
} */

/* Gilroy Font */

@font-face {
  font-family: "GilroyOne";
  src: url("./font/Gilroy/Gilroy-Thin.ttf");
}

@font-face {
  font-family: "GilroyTwo";
  src: url("./font/Gilroy/Gilroy-UltraLight.ttf");
}

@font-face {
  font-family: "GilroyThree";
  src: url("./font/Gilroy/Gilroy-Light.ttf");
}

@font-face {
  font-family: "GilroyFour";
  src: url("./font/Gilroy/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "GilroyFive";
  src: url("./font/Gilroy/Gilroy-SemiBold.ttf");
}

@font-face {
  font-family: "GilroySix";
  src: url("./font/Gilroy/Gilroy-Bold.ttf");
}

@font-face {
  font-family: "GilroySeven";
  src: url("./font/Gilroy/Gilroy-ExtraBold.ttf");
}

@font-face {
  font-family: "GilroyEight";
  src: url("./font/Gilroy/Gilroy-Heavy.ttf");
}

/* Gotham Font */

@font-face {
  font-family: "GothamOne";
  src: url("./font/Gotham/Gotham-Thin.otf");
}

@font-face {
  font-family: "GothamTwo";
  src: url("./font/Gotham/Gotham-XLight.otf");
}

@font-face {
  font-family: "GothamThree";
  src: url("./font/Gotham/Gotham-Light.otf");
}

@font-face {
  font-family: "GothamFour";
  src: url("./font/Gotham/GothamMedium.ttf");
}

@font-face {
  font-family: "GothamFive";
  src: url("./font/Gotham/GothamBook.ttf");
}

@font-face {
  font-family: "GothamSix";
  src: url("./font/Gotham/GothamBold.ttf");
}

/* @font-face {
  font-family: "GothamSeven";
  src: url("./font/Gotham/Gotham-ExtraBold.ttf");
}

@font-face {
  font-family: "GothamEight";
  src: url("./font/Gotham/Gotham-Heavy.ttf");
} */

/* Poppins Font */

@font-face {
  font-family: "PoppinsOne";
  src: url("./font/Poppins/Poppins-Thin.ttf");
}

@font-face {
  font-family: "PoppinsTwo";
  src: url("./font/Poppins/Poppins-ExtraLight.ttf");
}

@font-face {
  font-family: "PoppinsThree";
  src: url("./font/Poppins/Poppins-Light.ttf");
}

@font-face {
  font-family: "PoppinsFour";
  src: url("./font/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "PoppinsFive";
  src: url("./font/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "PoppinsSix";
  src: url("./font/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "PoppinsSeven";
  src: url("./font/Poppins/Poppins-ExtraBold.ttf");
}

/* @font-face {
  font-family: "PoppinsEight";
  src: url("./font/Poppins/Poppins-Heavy.ttf");
} */

.carousel-indicators {
  button {
    &.active {
      /* background-color: #18af1e !important; */
    }
  }
}
