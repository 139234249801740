* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.case_studies {
  width: 100%;
  height: max-content;

  .hero_section {
    width: 100%;
    height: 488.629px;
    position: relative;
    overflow: hidden;
    padding: 0;

    .header_text {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        color: #fff;
        font-weight: 400;
      }
    }

    img {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }

  .content_section {
    width: 100%;
    height: max-content;
    padding: 2.5rem 10rem;

    .info_wrapper {
      width: max-content;
      height: max-content;
      background-color: #f7f7f7;
      padding: 0.4rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      margin-bottom: 1rem;

      p {
        margin: 0;
        padding: 0;
        color: #6c7880;
        font-size: 14px;

        span {
          color: #19b01f;
        }
      }
    }

    .years_wrapper {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 1.5rem;

      p {
        margin: 0;
        padding: 0;
        font-size: 18px;
        font-weight: 500;
      }

      .year {
        width: max-content;
        height: max-content;
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: flex-start;
        padding-left: 1rem;

        .radio_btn {
          input {
            display: none;
          }

          label {
            border: 2px solid #19b01f;
            padding: 0.2rem 1rem;
            border-radius: 27px;
          }

          input[type="radio"]:checked + label {
            background-color: #19b01f;
            color: #fff;
            box-shadow: 0px 2.798px 12.659px 0px #8bff90;
          }
        }
      }
    }

    .articles_wrapper {
        width: 100%;
        height: max-content;
        display: flex;
        gap: 5rem;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        .article {
            width: 45%;
            max-height: 45rem;
            // background-color: orange;
            padding-bottom: 0.5rem;

            .image_container {
                width: 100%;
                height: 20rem;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                border-radius: 15px;

                .carousel-indicators {
                  button {
                    border-radius: 50%;
                    width: 12px;
                    height: 12px;

                    &.active {
                      background-color: #19b01f;
                    }
                  }
                }
            }

            h1 {
                font-size: 28px;
                font-weight: 900;
                margin-top: 1rem;
            }

            .tags_wrapper {
                width: 100%;
                height: max-content;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 1rem;

                .read_by_cat_btn {
                  &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                  }
                }

                p {
                    margin: 0;
                    padding: 0;
                    font-size: 14px;

                    &:nth-child(1) {
                        font-weight: 600;
                        color: #000;
                    }
                    
                    &:nth-child(3) {
                        font-size: 14px;
                        color: #19b01f;
                    }
                }

                span {
                    font-size: 12px;
                    color: #fff;
                    background-color: #19b01f;
                    border-radius: 27px;
                    padding: 0.1rem 0.5rem;
                    margin: 0 0.5rem;
                }
            }

            .desc {
                font-size: 16px;
                margin: 0;
                padding: 0;
                margin-bottom: 1.5rem;
            }

            .read_more_btn {
                width: max-content;
                height: max-content;
                background-color: #19b01f;
                color: #fff;
                text-decoration: none;
                padding: 0.4rem 0.7rem;
                border-radius: 27px;
                font-size: 14px;
                border: none;
                outline: none;

                .icon {
                    font-size: 20px;
                    margin-left: 0.3rem;
                    margin-right: 0.1rem;
                    margin-bottom: 2px;
                }
            }
        }
    }
  }
}

@media (max-width: 575.98px) {
  .case_studies {
    width: 100%;
    height: max-content;
  
    .hero_section {
      width: 100%;
      height: 244.629px;
      position: relative;
      overflow: hidden;
      padding: 0;
  
      .header_text {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
  
        h1 {
          color: #fff;
          font-weight: 400;
        }
      }
  
      img {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
  
    .content_section {
      width: 100%;
      height: max-content;
      padding: 1.5rem;
  
      .info_wrapper {
        width: max-content;
        height: max-content;
        background-color: #f7f7f7;
        padding: 0.4rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin-bottom: 1rem;
  
        p {
          margin: 0;
          padding: 0;
          color: #6c7880;
          font-size: 14px;
  
          span {
            color: #19b01f;
          }
        }
      }
  
      .years_wrapper {
        width: 100%;
        height: max-content;
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 1.5rem;
  
        p {
          margin: 0;
          padding: 0;
          font-size: 18px;
          font-weight: 500;
        }
  
        .year {
          width: max-content;
          height: max-content;
          display: flex;
          gap: 1rem;
          align-items: center;
          justify-content: flex-start;
          padding-left: 0rem;
  
          .radio_btn {
            input {
              display: none;
            }
  
            label {
              border: 2px solid #19b01f;
              padding: 0.2rem 1rem;
              border-radius: 27px;
            }
  
            input[type="radio"]:checked + label {
              background-color: #19b01f;
              color: #fff;
              box-shadow: 0px 2.798px 12.659px 0px #8bff90;
            }
          }
        }
      }
  
      .articles_wrapper {
          width: 100%;
          height: max-content;
          display: flex;
          gap: 5rem;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
  
          .article {
              width: 100%;
              max-height: 45rem;
              // background-color: orange;
              padding-bottom: 0.5rem;
  
              .image_container {
                  width: 100%;
                  height: 18rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  overflow: hidden;
                  border-radius: 15px;
  
                  .carousel-indicators {
                    button {
                      border-radius: 50%;
                      width: 12px;
                      height: 12px;
  
                      &.active {
                        background-color: #19b01f;
                      }
                    }
                  }
              }
  
              h1 {
                  font-size: 28px;
                  font-weight: 900;
                  margin-top: 1rem;
              }
  
              .tags_wrapper {
                  width: 100%;
                  height: max-content;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  margin-bottom: 1rem;
  
                  .read_by_cat_btn {
                    &:hover {
                      text-decoration: underline;
                      cursor: pointer;
                    }
                  }
  
                  p {
                      margin: 0;
                      padding: 0;
                      font-size: 14px;
  
                      &:nth-child(1) {
                          font-weight: 600;
                          color: #000;
                      }
                      
                      &:nth-child(3) {
                          font-size: 14px;
                          color: #19b01f;
                      }
                  }
  
                  span {
                      font-size: 12px;
                      color: #fff;
                      background-color: #19b01f;
                      border-radius: 27px;
                      padding: 0.1rem 0.5rem;
                      margin: 0 0.5rem;
                  }
              }
  
              .desc {
                  font-size: 16px;
                  margin: 0;
                  padding: 0;
                  margin-bottom: 1.5rem;
              }
  
              .read_more_btn {
                  width: max-content;
                  height: max-content;
                  background-color: #19b01f;
                  color: #fff;
                  text-decoration: none;
                  padding: 0.4rem 0.7rem;
                  border-radius: 27px;
                  font-size: 14px;
                  border: none;
                  outline: none;
  
                  .icon {
                      font-size: 20px;
                      margin-left: 0.3rem;
                      margin-right: 0.1rem;
                      margin-bottom: 2px;
                  }
              }
          }
      }
    }
  }
}
