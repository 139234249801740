* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.about2 {
  width: 100%;
  height: 100%;
  .about-container {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.vision_container_wrapper {
  width: 100%;
  height: max-content;

  .vision_wrapper {
    width: 100%;
    height: 350px;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    // background-color: orange;
    padding: 0 7.5rem;

    .vision {
      width: 50%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .global_wrapper {
    width: 100%;
    max-height: 40rem;
    padding: 0 7.5rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 80%;
      height: auto;
    }
  }
}

.about2-bottom {
  width: 100%;
  height: max-content;
  margin: 0;
  padding: 0%;
  overflow: hidden;
  // border: 1px solid red;
  .about-container {
    width: 100% !important;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 150%;
      height: auto;
      object-fit: cover;
      // border: 2px solid blue;
    }
  }
}
.about-us-logo {
  width: 394px;
  height: 270px;
  img {
    width: 100%;
    height: 100%;
  }
}

.about_us {
  width: 100%;
  height: max-content;

  .about_banner {
    width: 100%;
    height: 43rem;
    // background: url(./../../../public/Images/newhomeassets/thirdresidentcarousel.jpeg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10rem;

    .about_text_container {
      color: #fff;

      h1 {
        font-size: 54px;
        font-weight: 400;
        margin-bottom: 2rem;
      }

      h3 {
        font-size: 48px;
        font-weight: 900;
      }
    }
  }

  .main_text_container {
    width: 100%;
    height: max-content;
    padding: 5rem 10rem;

    .img_text_container {
      width: 100%;
      height: 24rem;
      display: flex;
      gap: 3rem;
      align-items: center;
      justify-content: space-between;
      padding-top: 2rem;

      .img_container {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 45px;
        box-shadow: 1px 1px 10px #757d8da0;

        img {
          width: 100%;
        }
      }

      .text_container {
        width: 50%;
        height: 100%;
        padding: 1rem 1rem 0 1rem;

        h3 {
          width: max-content;
          font-size: 16px;
          font-weight: 700;
          border-bottom: 2px solid #2b9b1f;
          padding: 0 2px;
          margin-bottom: 1rem;
        }

        h1 {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 1rem;
        }

        p {
          color: #757d8d;
        }
      }
    }

    .only_text_container {
      width: 100%;
      height: max-content;
      margin-top: 2.5rem;

      p {
        color: #757d8d;
      }
    }
  }

  .overview_wrapper {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2.5rem 10rem;

    h3 {
      width: max-content;
      font-size: 16px;
      font-weight: 700;
      border-bottom: 2px solid #2b9b1f;
      padding: 0 2px;
      margin-bottom: 1rem;
    }

    h1 {
      font-weight: 700;
    }
  }
}
.about-section {
  display: flex;
  width: 100%;
  height: 70vh;
  justify-content: center;
  align-content: center;
  .about-section-container {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 12%;
    .about-first {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 70%;
        object-fit: fill;
      }
    }
    .about-second {
      width: 50%;

      h1 {
        font-weight: 700;
        color: #19B01F;
      }

      p {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .about2 {
    width: 100%;
    height: 100%;
    
    .about-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden !important;
      img {
        width: auto;
        height: 14rem;
        object-fit: cover;
      }
    }
  }

  .vision_container_wrapper {
    width: 100%;
    height: max-content;
    overflow: hidden !important;
    margin-top: 2.5rem;
  
    .vision_wrapper {
      width: 100%;
      max-height: max-content;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
      justify-content: center;
      // background-color: orange;
      padding: 0 1.5rem;
  
      .vision {
        width: 100%;
        height: 50%;
        margin-top: 2rem;
  
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  
    .global_wrapper {
      width: 100%;
      max-height: 40rem;
      padding: 0 1.5rem;
      margin-top: 4rem;
      margin-bottom: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 7.5rem;
  
      img {
        width: 80%;
        height: auto;
      }
    }
  }

  .about2-bottom {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0%;
    overflow: hidden;
    // border: 1px solid red;
    .about-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 160%;
        height: auto;
        object-fit: cover;
        // border: 2px solid blue;
      }
    }
  }
  .about-us-logo {
    width: 394px;
    height: 270px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .about_us {
    width: 100%;
    height: max-content;

    .about_banner {
      width: 100%;
      height: 43rem;
      // background: url(./../../../public/Images/newhomeassets/thirdresidentcarousel.jpeg);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 10rem;

      .about_text_container {
        color: #fff;

        h1 {
          font-size: 54px;
          font-weight: 400;
          margin-bottom: 2rem;
        }

        h3 {
          font-size: 48px;
          font-weight: 900;
        }
      }
    }

    .main_text_container {
      width: 100%;
      height: max-content;
      padding: 5rem 10rem;

      .img_text_container {
        width: 100%;
        height: 24rem;
        display: flex;
        gap: 3rem;
        align-items: center;
        justify-content: space-between;
        padding-top: 2rem;

        .img_container {
          width: 50%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border-radius: 45px;
          box-shadow: 1px 1px 10px #757d8da0;

          img {
            width: 100%;
          }
        }

        .text_container {
          width: 50%;
          height: 100%;
          padding: 1rem 1rem 0 1rem;

          h3 {
            width: max-content;
            font-size: 16px;
            font-weight: 700;
            border-bottom: 2px solid #2b9b1f;
            padding: 0 2px;
            margin-bottom: 1rem;
          }

          h1 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 1rem;
          }

          p {
            color: #757d8d;
          }
        }
      }

      .only_text_container {
        width: 100%;
        height: max-content;
        margin-top: 2.5rem;

        p {
          color: #757d8d;
        }
      }
    }

    .overview_wrapper {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 2.5rem 10rem;

      h3 {
        width: max-content;
        font-size: 16px;
        font-weight: 700;
        border-bottom: 2px solid #2b9b1f;
        padding: 0 2px;
        margin-bottom: 1rem;
      }

      h1 {
        font-weight: 700;
      }
    }
  }
  .about-section {
    display: flex;
    width: 100%;
    height: max-content !important;
    justify-content: center;
    align-content: center;
    .about-section-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      height: 100%;
      justify-content: center;
      align-items: center;
      padding: 0 2rem;
      padding-top: 2.5rem;
      .about-first {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 70%;
          object-fit: fill;
        }
      }
      .about-second {
        width: 100%;

        h1 {
          font-weight: 700;
          color: #19B01F;
          font-size: 24px;
          text-align: center;
        }

        p {
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
}
