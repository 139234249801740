.subscribe {
  width: 100%;
  height: 35vh;
  background-color: #e7ffec;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid grey;
  .subscribe-container {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    .subsribe-input {
      width: 100%;
      // height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      .input-s {
        width: 20%;
        input {
          width: 100%;
          background-color: #e7ffec;
          border-radius: 7.523px;
          border: 0.94px solid #19b01f;
          outline: none;
          font-size: 13px;
          padding: 0.7rem;
          color: #19b01f;
          // box-shadow: 0.5px 0.5px 2px #19B01F;
          &::placeholder {
            color: #19b01f;
          }
        }
      }
      .subscribe-button {
        button {
          background-color: #19b01f;
          color: white;
        }
      }
    }
  }
}
