* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .commercial_enrich_first_section {
    height: 86vh;
    width: 100%;
    overflow: hidden;
    position: relative;
  
    .overlay {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.082);
    }
  
    .image_bg {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -2;
      overflow: hidden;
  
      img {
        width: 100%;
      }
    }
  
    .first_sec_contents {
      width: 60%;
      height: 100%;
      // background-color: red;
      padding-left: 10rem;
      padding-right: 5rem;
      padding-bottom: 0rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      color: #fff;
  
      p {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0;
      }
  
      h1 {
        font-size: 54px;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 0;
      }
    }
  }

  .commercial_freedom_second_section {
    width: 100%;
    height: max-content;
  
    .enrich_sec_sec_heading {
      width: 100%;
      height: max-content;
      padding-top: 2rem;
      padding-bottom: 0.5rem;
      padding-left: 7.5rem;
  
      h1 {
        font-weight: 900;
        color: #6f6f6f;
      }
    }
  
    .commercial_enrich_first_section {
      height: 100%;
      width: 100%;
      overflow: hidden;
  // border: 3px solid blue;
      #carouselExampleFade {
        height: 100%;
        width: 100%;
        // position: relative;
        .control_buttons {
          width: 100%;
          height: 20%;
          // background-color: orange;
          margin-left: 0;
          // border: 3px solid orange;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8rem;
  
          button {
            border-radius: 50%;
            width: 70px;
            height: 70px;
            background-color: #ffffff49;
            // border: 1px solid #fff;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1;
  // z-index: 999;
  stroke: white;
            .icon {
              font-size: 38px;
              // color: #fff;
              stroke: white;
              stroke-width: white;
              z-index: 999;
            }
            
            // &:active {
            //   background-color: #121212;
            // }
          }
  
          .active {
            width: 70px;
            height: 70px;
            border: 1px solid #fff;
            background-color: #fff;
            box-shadow: 0px 2.963px 24.075px 0px #C0F8C2;
            stroke: #19B01F;
  
            .icon {
              color: #19b01f;
            }
          }
  
          .line {
            position: absolute;
            width: 8.35rem;
            height: 0.25rem;
            background-color: #fff;
  
              &:nth-child(2) {
                position: absolute;
                left: calc(50% + 2.3rem);
              }
  
            &:nth-child(4) {
              position: absolute;
              left: calc(50% - 10.4rem);
              
            }
  
            &:nth-child(6) {
              position: absolute;
              left: calc(50% + 2.4rem);
            }
  
            // &:nth-child(8) {
            //   position: absolute;
            //   left: calc(50% + 15rem);
            // }
          }
        }
      }
  
      .carousel-inner {
        width: 100%;
        height: 100%;
        .first_sec_contents {
          position: absolute;
          top: 0;
          left: 0;
          // border: 3px solid green;
          width: 100%;
          height: 100%;
          // padding-left: 10rem;
          // padding-right: 5rem;
          // padding-bottom: 7.5rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          color: #fff;
  
          p {
            font-size: 13.83px;
            font-weight: 500;
            margin-bottom: 0.6rem;
          }
  
          h1 {
            font-size: 37.07px;
            text-transform: capitalize;
            font-weight: 800;
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }

  .commercial_freedom_third_section {
    width: 100%;
    height: 45rem;
    overflow: hidden;
    margin: 0;

    .heading_container {
      width: 100%;
      height: 7rem;
      display: flex ;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      h3 {
        font-size: 18px;
        font-weight: 700;
        margin: 0;
      }
  
      h1 {
        font-size: 32px;
        font-weight: 900;
        margin: 0;
        margin-top: 0.5rem;
      }
    }

    #carouselExampleCaptions {
      width: 100%;
      height: max-content;
      // padding-bottom: 5rem;
      display: flex;
      gap: 5rem;
      align-items: center;
      justify-content: center;
      margin-left: 0;
  
      .carousel-indicators {
        width: 100%;
        height: 38rem;
        padding-bottom: 2.5rem;
        display: flex;
        gap: 5rem;
        align-items: flex-end;
        justify-content: center;
        margin-left: 0;
        // background-color: #19b01f;
        top: 0;
  
        .imagebutton {
          width: 90px;
          height: 100px;
          overflow: hidden;
          border-radius: 10px;
          padding: 0;
          border: none;
  
          img {
            width: 102%;
            height: 102%;
          }
  
          &:hover {
            transform: scale(1.1);
          }

          &.active {
            box-shadow: none;
          }
        }
      }
  
      .arrow_btn {
        border: 3px solid #fff;
        border-radius: 50%;
        padding: 1rem;
      }
    }
  }
  
  .commercial_freedom_fourth_section_wrapper {
    width: 100%;
    height: max-content;
    background-color: #E7FFEB;
    padding-bottom: 2.5rem;
  
    .product_fourth_section {
      width: 100%;
      height: max-content;
      padding: 2.5rem 5rem;
      background-color: #fff;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
  
      .featured_products {
        width: 100%;
        height: max-content;
        //   padding-bottom: 7.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
  
        h1 {
          font-size: 34px;
          font-weight: 900;
          color: #6f6f6f;
          margin-bottom: 2.5rem;
        }
  
        .product_list {
          width: 100%;
          height: max-content;
          display: flex;
          gap: 2.5rem;
          align-items: center;
          justify-content: center;
  
          .product {
            width: 200px;
            height: 300px;
            border-radius: 0px;
            overflow: hidden;
  
            .name {
              width: 100%;
              height: max-content;
              background-color: #19b01f;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.3rem 0;
              border-radius: 5px;
              margin-bottom: 1rem;
  
              p {
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 0;
                color: #fff;
              }
            }
  
            .info {
              width: 100%;
              height: max-content;
              display: flex;
              flex-direction: column;
              gap: 1rem;
              align-items: center;
              justify-content: center;
              background-color: #f2f2f2;
              border-radius: 5px;
              padding: 1.5rem 0;
  
              button {
                padding: 0.3rem 1rem;
                border: none;
                outline: none;
                background-color: #19b01f;
                border-radius: 5px;
  
                a {
                  text-decoration: none;
                  color: #fff;
                  font-size: 14px;
                  font-weight: 700;
                }
              }
            }
          }
        }
  
        .dwnld_btn {
          margin-top: 1.5rem;
          button {
            padding: 0.3rem 1rem;
            border: none;
            outline: none;
            background-color: #19b01f;
            border-radius: 5px;
  
            a {
              text-decoration: none;
              color: #fff;
              font-size: 14px;
              font-weight: 700;
            }
          }
        }
      }
  
      .detailed_product_info_wrapper {
        width: 100%;
        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
  
        .detailed_product_info {
          width: 75%;
          height: 75%;
          background-color: #e7e7e7;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
  
          .product_image {
            width: 40%;
            height: 100%;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            background-image: linear-gradient(to top, #e7e7e7, #fff);
  
            img {
              width: 70%;
            }
          }
  
          .product_info {
            width: 60%;
            height: 100%;
            background-color: #19b01f;
            border-radius: 10px;
            padding: 5rem 2.5rem;
  
            .product_chng_button_wrapper {
              width: 100%;
              height: max-content;
              display: flex;
              gap: 2rem;
              align-items: center;
              justify-content: flex-start;
  
              button {
                height: max-content;
                background-color: #e31e24;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.3rem 2rem;
                border-radius: 5px;
                margin-bottom: 1rem;
                border: none;
                outline: none;
  
                &:nth-child(2) {
                  background-color: #fff;
  
                  a {
                    color: #525252;
                  }
                }
  
                a {
                  font-size: 16px;
                  font-weight: 900;
                  margin-bottom: 0;
                  color: #fff;
                  text-decoration: none;
                }
              }
            }
  
            .all_information {
              margin-top: 1.5rem;
              h1 {
                font-size: 22px;
                color: #fff;
              }
  
              p {
                font-size: 14px;
                color: #fff;
              }
  
              .cta_btn_wrapper {
                display: flex;
                gap: 1.5rem;
                align-items: center;
                button {
                  padding: 0.3rem 1rem;
                  border: none;
                  outline: none;
                  background-color: #fff;
                  border-radius: 5px;
                  margin-top: 1.5rem;
  
                  a {
                    text-decoration: none;
                    color: #776969;
                    font-size: 14px;
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }

      .Partner_with_us {
        width: 75%;
        height: 20rem;
        display: flex;
        align-items: center;
        margin: auto;
        justify-content: space-between;
        margin-bottom: 5rem;
  
        .partner_image {
          width: 50%;
          height: 100%;
          // background-color: orange;
          border-radius: 30px;
          box-shadow: -10px 10px 0 #19b01f;
          padding: 0;
          position: relative;
          overflow: hidden;
  
          img {
            width: 103%;
            height: 103%;
            margin: 0;
            position: absolute;
            top: 0;
            left: -10px;
          }
        }
  
        .partner_contents {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding-left: 4.5rem;
  
          h1 {
            font-size: 38px;
            color: #19b01f;
            font-weight: 900;
            margin: 0;
          }
  
          p {
            font-size: 20px;
            font-weight: 400;
            color: #2C2C2C;          ;
            margin: 0;
            margin-top: 0.6rem;
            line-height: 1.4;
          }
  
          button {
            padding: 0.4rem 1.5rem;
            background-color: #19b01f;
            border: none;
            outline: none;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1.5rem;
  
            a {
              font-size: 16px;
              color: #fff;
              text-decoration: none;
              font-weight: 600;
            }
            &:hover {
              background-color: #fff;
              box-shadow: 1px 1px 10px #19b01e2f;
              cursor: pointer;

              a {
                  color: #19B01F;
              }
          }
          }
        }
      }
    }
  }

  @media (max-width: 575.98px) {
    .res_third_section {
      width: 100%;
      height: 30vh;
      overflow: hidden;
    }
  
    .commercial_freedom_second_section {
      width: 100%;
      height: max-content;
    
      .enrich_sec_sec_heading {
        width: 100%;
        height: max-content;
        padding-top: 2rem;
        padding-bottom: 0.5rem;
        padding-left: 0rem;
    
        h1 {
          font-weight: 900;
          color: #6f6f6f;
          text-align: center;
        }
      }
    
      .commercial_enrich_first_section {
        height: 100%;
        width: 100%;
        overflow: hidden;
    
        #carouselExampleFade {
          height: 20rem;
          width: 100%;
    
          .control_buttons {
            width: 20%;
            height: 100%;
            background: transparent;
            margin-left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
    
            button {
              border-radius: 50%;
              width: 35px;
              height: 35px;
              background-color: #ffffff49;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 1;
              stroke: white;
    
              .icon {
                font-size: 38px;
                color: #fff;
              }
            }
    
            .active {
              width: 35px;
              height: 35px;
              border: 1px solid #fff;
              background-color: #fff;
              box-shadow: 0px 2.963px 24.075px 0px #C0F8C2;
              stroke: #19B01F;
    
              .icon {
                color: #19b01f;
              }
            }
    
            .line {
              position: absolute;
              width: 0.25rem;
              height: 1.25rem;
              background-color: #fff;
              display: none;
    
              &:nth-child(2) {
                position: absolute;
                top: calc(0);
              }
    
              &:nth-child(4) {
                position: absolute;
                top: calc(2rem);
                left: calc(1rem + 17.561px);
              }
            }
          }
        }
    
        .carousel-inner {
          width: 100%;
          height: 100%;
  
          img {
            height: 100%;
            width: auto;
          }
  
          .first_sec_contents {
            position: absolute;
            top: 2.5rem;
            left: 0;
            width: 100%;
            height: 100%;
            padding-left: 20%;
            padding-right: 2rem;
            padding-bottom: 7.5rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            color: #fff;
    
            p {
              font-size: 13.83px;
              font-weight: 500;
              margin-bottom: 0.6rem;
            }
    
            h1 {
              font-size: 28px;
              text-transform: capitalize;
              font-weight: 800;
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  
    .commercial_freedom_fourth_section_wrapper {
      width: 100%;
      height: max-content;
      background-color: #E7FFEB;
      padding-bottom: 2.5rem;
    
      .product_fourth_section {
        width: 100%;
        height: max-content;
        padding: 2.5rem 1rem;
        background-color: #fff;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
    
        .featured_products {
          width: 100%;
          height: max-content;
          //   padding-bottom: 7.5rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
    
          h1 {
            font-size: 34px;
            font-weight: 900;
            color: #6f6f6f;
            margin-bottom: 2.5rem;
          }
    
          .product_list {
            width: 100%;
            height: max-content;
            display: flex;
            gap: 2.5rem;
            align-items: center;
            justify-content: center;
    
            .product {
              width: 200px;
              height: 300px;
              border-radius: 0px;
              overflow: hidden;
    
              .name {
                width: 100%;
                height: max-content;
                background-color: #e31e24;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.3rem 0;
                border-radius: 5px;
                margin-bottom: 1rem;
    
                p {
                  font-size: 14px;
                  font-weight: 700;
                  margin-bottom: 0;
                  color: #fff;
                }
              }
    
              .info {
                width: 100%;
                height: max-content;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: center;
                justify-content: center;
                background-color: #f2f2f2;
                border-radius: 5px;
                padding: 1.5rem 0;
    
                button {
                  padding: 0.3rem 1rem;
                  border: none;
                  outline: none;
                  background-color: #19b01f;
                  border-radius: 5px;
    
                  a {
                    text-decoration: none;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 700;
                  }
                }
              }
            }
          }
    
          .dwnld_btn {
            margin-top: 1.5rem;
            button {
              padding: 0.3rem 1rem;
              border: none;
              outline: none;
              background-color: #19b01f;
              border-radius: 5px;
    
              a {
                text-decoration: none;
                color: #fff;
                font-size: 14px;
                font-weight: 700;
              }
            }
          }
        }
    
        .detailed_product_info_wrapper {
          width: 100%;
          height: 80vh;
          display: flex;
          align-items: center;
          justify-content: center;
    
          .detailed_product_info {
            width: 75%;
            height: 75%;
            background-color: #e7e7e7;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
    
            .product_image {
              width: 40%;
              height: 100%;
              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
              background-image: linear-gradient(to top, #e7e7e7, #fff);
    
              img {
                width: 70%;
              }
            }
    
            .product_info {
              width: 60%;
              height: 100%;
              background-color: #19b01f;
              border-radius: 10px;
              padding: 5rem 2.5rem;
    
              .product_chng_button_wrapper {
                width: 100%;
                height: max-content;
                display: flex;
                gap: 2rem;
                align-items: center;
                justify-content: flex-start;
    
                button {
                  height: max-content;
                  background-color: #e31e24;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0.3rem 2rem;
                  border-radius: 5px;
                  margin-bottom: 1rem;
                  border: none;
                  outline: none;
    
                  &:nth-child(2) {
                    background-color: #fff;
    
                    a {
                      color: #525252;
                    }
                  }
    
                  a {
                    font-size: 16px;
                    font-weight: 900;
                    margin-bottom: 0;
                    color: #fff;
                    text-decoration: none;
                  }
                }
              }
    
              .all_information {
                margin-top: 1.5rem;
                h1 {
                  font-size: 22px;
                  color: #fff;
                }
    
                p {
                  font-size: 14px;
                  color: #fff;
                }
    
                .cta_btn_wrapper {
                  display: flex;
                  gap: 1.5rem;
                  align-items: center;
                  button {
                    padding: 0.3rem 1rem;
                    border: none;
                    outline: none;
                    background-color: #fff;
                    border-radius: 5px;
                    margin-top: 1.5rem;
    
                    a {
                      text-decoration: none;
                      color: #776969;
                      font-size: 14px;
                      font-weight: 700;
                    }
                  }
                }
              }
            }
          }
        }
    
        .Partner_with_us {
          width: 100%;
          height: max-content;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: auto;
          justify-content: center;
          margin-top: 0;
    
          .partner_image {
            width: 100%;
            min-height: 20rem;
            background-color: orange;
            border-radius: 30px;
            box-shadow: -10px 10px 0 #19b01f;
            padding: 0;
            position: relative;
            overflow: hidden;
            margin: 1.5rem 0;
    
            img {
              width: 103%;
              height: 103%;
              margin: 0;
              position: absolute;
              top: 0;
              left: -10px;
            }
          }
    
          .partner_contents {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-left: 0;
    
            h1 {
              font-size: 28px;
              color: #19b01f;
              font-weight: 900;
              margin: 0;
              text-align: center;
            }
    
            p {
              font-size: 18px;
              font-weight: 400;
              color: #2c2c2c;
              margin: 0;
              margin-top: 0.6rem;
              line-height: 1.4;
              text-align: center;
            }
    
            button {
              padding: 0.4rem 1.5rem;
              background-color: #19b01f;
              border: none;
              outline: none;
              border-radius: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 1.5rem;
    
              a {
                font-size: 16px;
                color: #fff;
                text-decoration: none;
                font-weight: 600;
              }
              &:hover {
                background-color: #fff;
                box-shadow: 1px 1px 10px #19b01e2f;
                cursor: pointer;
    
                a {
                  color: #19b01f;
                }
              }
            }
          }
        }
      }
    }
  }
  