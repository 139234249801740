* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Header {
  width: 100%;
  height: 6rem;
  position: sticky;
  top: -1.5rem;
  z-index: 99999;

  .top_header_div {
    width: 100%;
    height: 1.5rem;
    background-color: #19b01f;
    padding: 0 5rem;
    padding-top: 1vh;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
      font-size: 14px;
      color: #fff;
      text-transform: uppercase;
      font-family: "GothamSix";
      margin: 0;
      padding: 0;
    }
  }

  .bottom_header_div {
    width: 100%;
    height: 4.5rem;
    background-color: rgba(255, 255, 255, 0.968);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5rem 0 3rem;

    .left_part_navigation {
      width: 70%;
      height: 100%;
      // background-color: orange;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .logo_container {
        width: max-content;
        height: max-content;

        img {
          width: 85%;
        }
      }

      .navigation_links {
        width: max-content;
        height: 100%;
        display: flex;
        gap: 2.5rem;
        align-items: center;
        justify-content: space-around;
        margin-left: 2rem;
        margin-bottom: 0;
        // padding-top: 0.7rem;

        li {
          list-style-type: none;

          a {
            text-decoration: none;
            color: #262626;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            transition: 0.4s;

            &:hover {
              color: #19b01f;
            }

            &.active {
              color: #19b01f;
            }
          }
        }
      }
    }

    .right_part_flows {
      width: 30%;
      height: 100%;
      // background-color: orange;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .flow_changer {
        width: max-content;
        height: 100%;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0;
        padding-top: 0.8rem;

        li {
          list-style-type: none;
          font-size: 14px;
          color: #262626;
          font-family: "GothamSix";
          text-transform: uppercase;

          &:hover {
            cursor: pointer;
          }

          &:nth-child(2) {
            &:hover {
              cursor: auto;
            }
          }
        }
      }

      .menu_button {
        width: max-content;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 34px;
        margin-left: 2rem;

        .menu_button_icon {
          transition: 0.5s ease;

          &:hover {
            cursor: pointer;
            color: #19b01f;
          }
        }
      }
    }
  }
}

.side_navbar {
  z-index: 999999;
  width: 18%;
  height: 100vh;
  background-color: #19b01f;
  position: fixed;
  top: 0;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 6vh 0 2vh 4rem;
  transition: 0.5s ease-in-out;

  .sidebar_close_button {
    height: 20%;
    width: 100%;
    // background-color: orange;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .sidebar_close_button_icon {
      font-size: 32px;
      color: #fff;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .sidebar_nav_links {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 4vh;
    padding: 0;

    li {
      list-style-type: none;
      border-bottom: 1px solid #fff;
      padding-bottom: 1vh;

      a,
      p {
        text-decoration: none;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        margin: 0;
      }
    }
  }

  .social_media_handles {
    width: 100%;
    height: max-content;
    display: flex;
    gap: 1.5rem;
    padding-top: 6vh;
    padding-right: 4rem;
    padding-left: 0;
    // background-color: orange;

    li {
      list-style-type: none;
      padding-bottom: 0.5rem;

      a {
        text-decoration: none;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
}

.mobile_side_navbar {
  z-index: 999999;
  width: 17rem;
  height: 100vh ;
  background-color: #19b01f;
  position: fixed !important;
  top: 0;
  padding: 1.5rem 0 1.5rem 1.5rem;
  transition: 0.5s ease-in-out;
  display: none;

  .sidebar_close_button {
    height: max-content;
    width: 100%;
    // background-color: orange;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .sidebar_close_button_icon {
      font-size: 28px;
      color: #fff;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .right_part_flows {
    width: 100%;
    height: max-content;
    // background-color: orange;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin-top: 1rem;

    .flow_changer {
      width: 100%;
      height: max-content;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0;
      margin-left: -2rem;

      li {
        list-style-type: none;
        font-size: 14px;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;

        &:hover {
          cursor: pointer;
        }

        &:nth-child(2) {
          &:hover {
            cursor: auto;
          }
        }
      }
    }
  }

  .sidebar_nav_links {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
    // background-color: orange;

    li {
      list-style-type: none;
      border-bottom: 1px solid #fff;
      padding-bottom: 0.5rem;

      a,
      p {
        text-decoration: none;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  .social_media_handles {
    width: 100%;
    height: max-content;
    display: flex;
    gap: 1.5rem;
    padding-top: 0rem;
    padding-right: 0rem;
    padding-left: 0;
    // background-color: orange;

    li {
      list-style-type: none;
      padding-bottom: 0.5rem;

      a {
        text-decoration: none;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
}

// Responsive Starts Here:::::::

/* Extra small devices (phones, less than 576px) */
@media (max-width: 991.98px) {
  .Header {
    width: 100%;
    height: 6rem;
    position: sticky;
    top: -1.5rem;
    z-index: 99999;

    .top_header_div {
      width: 100%;
      height: 1.5rem;
      background-color: #19b01f;
      padding: 0 1.5rem;
      padding-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        font-size: 12px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0;
        margin-top: 0.5rem;
      }
    }

    .bottom_header_div {
      width: 100%;
      height: 4.5rem;
      background-color: rgba(255, 255, 255, 0.968);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem 0 1rem;

      .left_part_navigation {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .logo_container {
          width: max-content;
          height: max-content;

          img {
            width: 85%;
          }
        }

        .navigation_links {
          display: none;
        }
      }

      .right_part_flows {
        width: 70%;

        .flow_changer {
          padding-top: 0.4rem;
          li {
            font-size: 10px;
          }
        }

        .menu_button {
          width: max-content;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 34px;
          margin-left: 1rem;

          .menu_button_icon {
            transition: 0.5s ease;

            &:hover {
              cursor: pointer;
              color: #19b01f;
            }
          }
        }
      }
    }
  }

  .side_navbar {
    display: none;
  }
  .mobile_side_navbar {
    display: block;
  }
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* CSS styles here */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* CSS styles here */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* CSS styles here */
}
