* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.res_third_section {
  width: 100%;
  height: 86vh;
  overflow: hidden;
}

.residental-features-heaidng {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  .residental-features-heaidng-con {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    h1 {
      font-size: 34px;
      font-weight: 900;
      color: #6f6f6f;
      // color: red;
      margin-bottom: 2.5rem;
    }
  }
}

.residental-features-step-up {
  // border: 1px solid red;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .resitental-features-container {
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid blue;
    gap: 20px;

    .residetal-sidebar-first {
      width: 353.559px;
      // height: 26rem;
      height: 479.431px;

      // width: 35%;
      // height: 65vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #19b01f;
      border-radius: 28px;
      gap: 16px;
      padding: 1.4rem;

      .resitentl-colmn1 {
        border-radius: 50%;
        height: 27%;
        display: flex;
        justify-content: center;
        align-items: center;

        .resitentl-colmn1-icon {
          font-size: 80px;
          width: 120px;
          height: 120px;
          text-align: center;
          background-color: #fff;
          box-shadow: 0px 2.615px 21.248px 0px #c0f8c2;
          border-radius: 50%;
        }
      }

      .resitentl-colmn2 {
        color: white;
        height: 12%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        // border: 2px solid white;
        h1 {
          font-weight: 700;
          font-size: 22px;
          padding: 0px;
          margin: 0px;
        }
      }

      .resitentl-colmn3 {
        padding: 0rem;
        height: 53%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        //  border: 2px solid red;
        // ul {
        //   li {
        //     color: #fff;
        //     text-align: left;

        //     font-size: 14px;
        //   }
        // }

        ul {
          color: #fff;
          text-align: left;
          display: flex;
          justify-content: flex-start;
          gap: 10px;
          flex-direction: column;
          font-size: 13px;
        }
      }

      .resitentl-colmn4 {
        height: 8%;
        display: flex;
        gap: 15px;
        justify-content: space-around;
        display: flex;
        justify-content: center;
        align-items: center;
        .filter-delhi {
          width: 38px;
          height: 38px;
          // background-color: #fff;
          border: 1px solid white;
          // stroke: white;
          stroke: white;
          border-radius: 50%;
          color: white;
          cursor: pointer;
          &:hover {
            background-color: #fff;
            stroke: #19b01f;
          }
        }
        .selected {
          background-color: #fff;
          stroke: #19b01f;
        }
      }
    }

    .residetal-sidebar-second {
      // width: 65%;
      // // border: 8px solid green;
      // height: 65vh;
      width: 798.883px;
      // height: 26rem;
      height: 479.431px;
      position: relative;
      // overflow: hidden;

      .arrow-left {
        position: absolute;
        top: 45%;

        cursor: pointer;
        left: 3%;

        .large-icon {
          font-size: 70px;
          color: #19b01f;
        }
      }

      .small-arrow-left {
        position: absolute;
        top: 48%;
        left: 5%;
        // transform: translate(-50%, -50%);
        width: auto;
        height: auto;

        cursor: pointer;

        .large-icon-small {
          font-size: 35px;
          color: #fff;
        }
      }

      .arrow-right {
        position: absolute;
        top: 45%;

        cursor: pointer;
        right: 3%;

        .large-icon {
          font-size: 70px;
          color: green;
        }
      }

      .small-arrow-right {
        position: absolute;
        top: 48%;
        right: 6%;
        // transform: translate(-50%, -50%);
        width: auto;
        height: auto;

        cursor: pointer;

        .large-icon-small {
          font-size: 35px;
          color: #fff;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 28px;
      }
    }
  }
}

.freedom_third_section_wrapper {
  width: 100%;
  height: max-content;
  background-color: #121212;
  padding-bottom: 2.5rem;

  .product_fourth_section {
    width: 100%;
    height: max-content;
    padding: 2.5rem 5rem;
    background-color: #fff;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;

    .featured_products {
      width: 100%;
      height: max-content;
      //   padding-bottom: 7.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 34px;
        font-weight: 900;
        color: #6f6f6f;
        margin-bottom: 2.5rem;
      }

      .product_list {
        width: 100%;
        height: max-content;
        display: flex;
        gap: 2.5rem;
        align-items: center;
        justify-content: center;

        .product {
          width: 200px;
          height: 300px;
          border-radius: 0px;
          overflow: hidden;

          .name {
            width: 100%;
            height: max-content;
            background-color: #19b01f;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.3rem 0;
            border-radius: 5px;
            margin-bottom: 1rem;

            p {
              font-size: 14px;
              font-weight: 700;
              margin-bottom: 0;
              color: #fff;
            }
          }

          .info {
            width: 100%;
            height: max-content;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            background-color: #f2f2f2;
            border-radius: 5px;
            padding: 1.5rem 0;

            button {
              padding: 0.3rem 1rem;
              border: none;
              outline: none;
              background-color: #19b01f;
              border-radius: 5px;

              a {
                text-decoration: none;
                color: #fff;
                font-size: 14px;
                font-weight: 700;
              }
            }
          }
        }
      }

      .dwnld_btn {
        margin-top: 1.5rem;

        button {
          padding: 0.3rem 1rem;
          border: none;
          outline: none;
          background-color: #19b01f;
          border-radius: 5px;

          a {
            text-decoration: none;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }

    .detailed_product_info_wrapper {
      width: 100%;
      height: 80vh;
      display: flex;
      align-items: center;
      justify-content: center;

      .detailed_product_info {
        width: 75%;
        height: 75%;
        background-color: #e7e7e7;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;

        .product_image {
          width: 40%;
          height: 100%;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          background-image: linear-gradient(to top, #e7e7e7, #fff);

          img {
            width: 70%;
          }
        }

        .product_info {
          width: 60%;
          height: 100%;
          background-color: #19b01f;
          border-radius: 10px;
          padding: 5rem 2.5rem;

          .product_chng_button_wrapper {
            width: 100%;
            height: max-content;
            display: flex;
            gap: 2rem;
            align-items: center;
            justify-content: flex-start;

            button {
              height: max-content;
              background-color: #e31e24;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.3rem 2rem;
              border-radius: 5px;
              margin-bottom: 1rem;
              border: none;
              outline: none;

              &:nth-child(2) {
                background-color: #fff;

                a {
                  color: #525252;
                }
              }

              a {
                font-size: 16px;
                font-weight: 900;
                margin-bottom: 0;
                color: #fff;
                text-decoration: none;
              }
            }
          }

          .all_information {
            margin-top: 1.5rem;

            h1 {
              font-size: 22px;
              color: #fff;
            }

            p {
              font-size: 14px;
              color: #fff;
            }

            .cta_btn_wrapper {
              display: flex;
              gap: 1.5rem;
              align-items: center;

              button {
                padding: 0.3rem 1rem;
                border: none;
                outline: none;
                background-color: #fff;
                border-radius: 5px;
                margin-top: 1.5rem;

                a {
                  text-decoration: none;
                  color: #776969;
                  font-size: 14px;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }

    .search_service {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: h;

      .area_image {
        width: 50%;
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 65%;
        }
      }

      .search_box_wrapper {
        width: 50%;
        height: max-content;
        padding: 0 5rem;
        overflow: hidden;

        h1 {
          font-weight: 900;
          text-transform: uppercase;
          margin-bottom: 2.5rem;
          color: #19b01f;
        }

        .search_input_wrapper {
          width: 100%;
          height: max-content;
          display: flex;
          gap: 1rem;
          margin-top: 1rem;
          overflow: hidden;

          .search_input {
            width: 65%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border: 1px solid #fff;
            padding-left: 0.5rem;
            border-radius: 5px;
            background-color: rgba(0, 0, 0, 0.364);

            .loc_icon {
              font-size: 24px;
              color: #fff;
            }

            input {
              font-size: 16px;
              padding: 0.7rem 0.5rem;
              width: 100%;
              border: none;
              outline: none;
              background: transparent;
              color: #fff;
            }
          }

          .search_button {
            width: 25%;
            border-radius: 5px;
            border: none;
            font-size: 16px;
            font-weight: 700;
            color: #fff;
            background-color: #19b01f;
            transition: 0.5s ease;

            &:hover {
              cursor: pointer;
              background-color: #fff;
              color: #19b01f;
              box-shadow: 1px 1px 5px #19b01e78;
            }
          }
        }
      }
    }
  }
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  .res_third_section {
    width: 100%;
    height: 30vh;
    overflow: hidden;
  }

  .residental-features-heaidng {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0;
    padding-left: 0;
    padding-right: 0;

    .residental-features-heaidng-con {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;

      h1 {
        width: 100% !important;
        height: 100% !important;
        font-size: 24px;
        font-weight: 900;
        color: #6f6f6f;
        // color: red;
        margin-bottom: 0rem !important;
        text-align: center;
      }
    }
  }

  .residental-features-step-up {
    // border: 1px solid red;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .resitental-features-container {
      width: 90%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // border: 1px solid blue;
      gap: 20px;

      .residetal-sidebar-first {
        width: 100%;
        // height: 26rem;
        min-height: 479.431px;

        // width: 35%;
        // height: 65vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #19b01f;
        border-radius: 28px;
        gap: 16px;
        padding: 1.4rem;

        .resitentl-colmn1 {
          border-radius: 50%;
          height: 27%;
          display: flex;
          justify-content: center;
          align-items: center;

          .resitentl-colmn1-icon {
            font-size: 80px;
            width: 120px;
            height: 120px;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            background-color: #fff;
            box-shadow: 0px 2.615px 21.248px 0px #c0f8c2;
            border-radius: 50%;
            stroke: #19b01f;
          }
        }

        .resitentl-colmn2 {
          color: white;
          height: 12%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          // border: 2px solid white;
          h1 {
            font-weight: 700;
            font-size: 22px;
            padding: 0px;
            margin: 0px;
          }
        }

        .resitentl-colmn3 {
          padding: 0rem;
          height: 53%;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 20px;
          //  border: 2px solid red;
          // ul {
          //   li {
          //     color: #fff;
          //     text-align: left;

          //     font-size: 14px;
          //   }
          // }

          ul {
            color: #fff;
            text-align: left;
            display: flex;
            justify-content: flex-start;
            gap: 10px;
            flex-direction: column;
            font-size: 13px;
          }
        }

        .resitentl-colmn4 {
          height: 8%;
          display: flex;
          gap: 15px;
          justify-content: space-around;
          display: flex;
          justify-content: center;
          align-items: center;

          .filter-delhi {
            width: 38px;
            height: 38px;
            // background-color: #fff;
            border: 1px solid white;
            // stroke: white;
            stroke: white;
            border-radius: 50%;
            color: white;
            cursor: pointer;
            &:hover {
              background-color: #fff;
              stroke: #19b01f;
            }
          }
          .selected {
            background-color: #fff;
            stroke: #19b01f;
          }
        }
      }

      .residetal-sidebar-second {
        width: 100%;
        // height: 26rem;
        height: max-content;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .arrow-left {
          position: absolute;
          top: 45%;

          cursor: pointer;
          left: 3%;

          .large-icon {
            font-size: 70px;
            color: #19b01f;
          }
        }

        .small-arrow-left {
          position: absolute;
          top: 48%;
          left: 5%;
          // transform: translate(-50%, -50%);
          width: auto;
          height: auto;

          cursor: pointer;

          .large-icon-small {
            font-size: 35px;
            color: #fff;
          }
        }

        .arrow-right {
          position: absolute;
          top: 45%;
          cursor: pointer;
          right: 3%;

          .large-icon {
            font-size: 70px;
            color: green;
          }
        }

        .small-arrow-right {
          position: absolute;
          top: 48%;
          right: 6%;
          // transform: translate(-50%, -50%);
          width: auto;
          height: auto;

          cursor: pointer;

          .large-icon-small {
            font-size: 35px;
            color: #fff;
          }
        }

        img {
          height: auto;
          width: 100%;
          object-fit: cover;
          border-radius: 28px;
        }
      }
    }
  }

  .freedom_third_section_wrapper {
    width: 100%;
    height: max-content;
    background-color: #121212;
    padding-bottom: 2.5rem;

    .product_fourth_section {
      width: 100%;
      height: max-content;
      padding: 2.5rem 5rem;
      background-color: #fff;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;

      .featured_products {
        width: 100%;
        height: max-content;
        //   padding-bottom: 7.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
          font-size: 34px;
          font-weight: 900;
          color: #6f6f6f;
          margin-bottom: 2.5rem;
        }

        .product_list {
          width: 100%;
          height: max-content;
          display: flex;
          gap: 2.5rem;
          align-items: center;
          justify-content: center;

          .product {
            width: 200px;
            height: 300px;
            border-radius: 0px;
            overflow: hidden;

            .name {
              width: 100%;
              height: max-content;
              background-color: #19b01f;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.3rem 0;
              border-radius: 5px;
              margin-bottom: 1rem;

              p {
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 0;
                color: #fff;
              }
            }

            .info {
              width: 100%;
              height: max-content;
              display: flex;
              flex-direction: column;
              gap: 1rem;
              align-items: center;
              justify-content: center;
              background-color: #f2f2f2;
              border-radius: 5px;
              padding: 1.5rem 0;

              button {
                padding: 0.3rem 1rem;
                border: none;
                outline: none;
                background-color: #19b01f;
                border-radius: 5px;

                a {
                  text-decoration: none;
                  color: #fff;
                  font-size: 14px;
                  font-weight: 700;
                }
              }
            }
          }
        }

        .dwnld_btn {
          margin-top: 1.5rem;

          button {
            padding: 0.3rem 1rem;
            border: none;
            outline: none;
            background-color: #19b01f;
            border-radius: 5px;

            a {
              text-decoration: none;
              color: #fff;
              font-size: 14px;
              font-weight: 700;
            }
          }
        }
      }

      .detailed_product_info_wrapper {
        width: 100%;
        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .detailed_product_info {
          width: 75%;
          height: 75%;
          background-color: #e7e7e7;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;

          .product_image {
            width: 40%;
            height: 100%;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            background-image: linear-gradient(to top, #e7e7e7, #fff);

            img {
              width: 70%;
            }
          }

          .product_info {
            width: 60%;
            height: 100%;
            background-color: #19b01f;
            border-radius: 10px;
            padding: 5rem 2.5rem;

            .product_chng_button_wrapper {
              width: 100%;
              height: max-content;
              display: flex;
              gap: 2rem;
              align-items: center;
              justify-content: flex-start;

              button {
                height: max-content;
                background-color: #e31e24;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.3rem 2rem;
                border-radius: 5px;
                margin-bottom: 1rem;
                border: none;
                outline: none;

                &:nth-child(2) {
                  background-color: #fff;

                  a {
                    color: #525252;
                  }
                }

                a {
                  font-size: 16px;
                  font-weight: 900;
                  margin-bottom: 0;
                  color: #fff;
                  text-decoration: none;
                }
              }
            }

            .all_information {
              margin-top: 1.5rem;

              h1 {
                font-size: 22px;
                color: #fff;
              }

              p {
                font-size: 14px;
                color: #fff;
              }

              .cta_btn_wrapper {
                display: flex;
                gap: 1.5rem;
                align-items: center;

                button {
                  padding: 0.3rem 1rem;
                  border: none;
                  outline: none;
                  background-color: #fff;
                  border-radius: 5px;
                  margin-top: 1.5rem;

                  a {
                    text-decoration: none;
                    color: #776969;
                    font-size: 14px;
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }

      .search_service {
        width: 100%;
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: h;

        .area_image {
          width: 50%;
          height: max-content;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 65%;
          }
        }

        .search_box_wrapper {
          width: 50%;
          height: max-content;
          padding: 0 5rem;
          overflow: hidden;

          h1 {
            font-weight: 900;
            text-transform: uppercase;
            margin-bottom: 2.5rem;
            color: #19b01f;
          }

          .search_input_wrapper {
            width: 100%;
            height: max-content;
            display: flex;
            gap: 1rem;
            margin-top: 1rem;
            overflow: hidden;

            .search_input {
              width: 65%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              border: 1px solid #fff;
              padding-left: 0.5rem;
              border-radius: 5px;
              background-color: rgba(0, 0, 0, 0.364);

              .loc_icon {
                font-size: 24px;
                color: #fff;
              }

              input {
                font-size: 16px;
                padding: 0.7rem 0.5rem;
                width: 100%;
                border: none;
                outline: none;
                background: transparent;
                color: #fff;
              }
            }

            .search_button {
              width: 25%;
              border-radius: 5px;
              border: none;
              font-size: 16px;
              font-weight: 700;
              color: #fff;
              background-color: #19b01f;
              transition: 0.5s ease;

              &:hover {
                cursor: pointer;
                background-color: #fff;
                color: #19b01f;
                box-shadow: 1px 1px 5px #19b01e78;
              }
            }
          }
        }
      }
    }
  }
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* CSS styles here */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* CSS styles here */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* CSS styles here */
}
