* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.blogs_wrapper {
  width: 100%;
  height: max-content;

  .top_banner {
    width: 100%;
    height: 25rem;
    // background-color: red;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.587);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #fff;

      h1 {
        font-weight: 800;
      }

      p {
        font-weight: 600;

        span {
          color: #18af1e;
        }
      }
    }

    img {
      width: 100%;
      // position: absolute;
      top: 0;
      left: 0;
    }
  }

  .main_body_wrapper {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .main_blogs_wrapper {
      width: 65%;
      height: max-content;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 2.5rem 0;

      .blog {
        width: 90%;
        height: max-content;
        overflow: hidden;
        margin-top: 2.5rem;

        .img_wrapper {
            width: 100%;
            height: 30rem;
            overflow: hidden;
            // border: 2px solid black;
            margin-bottom: 1rem;
            border-radius: 15px;

            img {
                width: 100%;
            }
        }

        .basic_info {
            width: 100%;
            height: max-content;

            .category {
                font-size: 16px;
                color: #232323;
                font-weight: 600;
            }

            .heading {
                font-size: 26px;
                color: #000;
                font-weight: 700;
                transition: 0.5s;

                &:hover {
                    color: #18af1e;
                }
            }

            .desc {
                font-size: 16px;
            }

            .read_more {
                color: #18af1e;

                &:hover {
                  cursor: pointer;
                }
            }
        }
      }
    }

    .category_wrapper {
      width: 35%;
      height: max-content;
    //   background-color: green;
      padding: 5rem 10% 2.5rem 5rem;

      .recent_posts {
        width: 100%;
        height: max-content;

        h1 {
            font-size: 18px;
            font-weight: 600;
            color: #000;
            margin-bottom: 1rem;
        }

        .post {
            margin-bottom: 1.5rem;
            p {
                margin: 0;
                font-size: 16px;
                margin-bottom: 0.5rem;

                .icon {
                    font-size: 20px;
                    margin-bottom: 3px;
                    margin-right: 5px;
                }
            }

            a {
                color: #000;
                font-weight: 600;

                &:hover {
                    color: #18af1e;
                }
            }
        }
      }
      .categories {
        width: 100%;
        height: max-content;
        margin-top: 2rem;

        h1 {
            font-size: 20px;
            font-weight: 600;
            color: #000;
            margin-bottom: 1rem;
        }

        .cat {
            p {
                margin: 0;
                font-size: 16px;
                margin-bottom: 0.5rem;
                display: inline;

                .icon {
                    font-size: 20px;
                    margin-bottom: 3px;
                    margin-right: 5px;
                    font-weight: 800;
                    color: #18af1e;
                }
            }

            a {
                color: #000;
                font-weight: 500;

                &:hover {
                    color: #18af1e;
                }
            }
        }
      }
    }
  }
}
