* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.product_first_section {
  height: 86vh;
  width: 100%;
  overflow: hidden;
  position: relative;

  .overlay {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.082);
  }

  .image_bg {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
    overflow: hidden;

    img {
        width: 100%;
    }
  }

  .first_sec_contents {
    width: 50%;
    height: 100%;
    // background-color: red;
    padding-left: 10rem;
    padding-right: 5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #fff;

    p {
      font-size: 18px;
      font-weight: 600;
    }

    h1 {
      font-size: 48px;
      text-transform: uppercase;
      font-weight: 900;
      margin-bottom: 1rem;
    }

    .search_input_wrapper {
      width: 100%;
      height: max-content;
      display: flex;
      gap: 1rem;
      margin-top: 1rem;

      .search_input {
        width: 65%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid #fff;
        padding-left: 0.5rem;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.045);

        .loc_icon {
          font-size: 24px;
        }

        input {
          font-size: 16px;
          padding: 0.7rem 0.5rem;
          width: 100%;
          border: none;
          outline: none;
          background: transparent;
          color: #fff;
        }
      }

      .search_button {
        width: 25%;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        background-color: #19b01f;
        transition: 0.5s ease;

        &:hover {
          cursor: pointer;
          background-color: #fff;
          color: #19b01f;
        }
      }
    }
  }
}
