* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.resident-solar-revolution {
  width: 100%;
  height: 90vh;
  position: relative;

  .resident-solar-evo-container {
    z-index: -1;
    width: 100%;
    height: 30%;
    overflow: hidden;

    video {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .image-residental {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
    gap: 40px;
    padding: 0 5rem;

    .image_wrapper {
      width: 50%;
      height: max-content;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        border-radius: 15px;
      }

      .dark_overlay {
        position: absolute;
        bottom: 2%;
        left: 10%;
        background-color: #fff;
        border-radius: 10px;
        width: 203.345px;
        height: 106.897px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 12.618px 17.813px 0px rgba(0, 0, 0, 0.08);

        h1 {
          font-size: 41.721px;
          font-weight: 800;
          margin-bottom: 0;
          color: #19b01f;
        }

        p {
          margin: 0;
          color: #656565;
          font-size: 11.561px;
          font-weight: 300;
          text-transform: capitalize;
        }
      }
    }

    .content_container {
      width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 7.5rem 0 2rem;

      p {
        font-size: 13px;
        font-weight: 300;
        color: black;
        font-family: "GilroyFive";
        margin: 0;
        margin-bottom: 0.2rem;

        &:nth-child(3) {
          font-weight: 500;
          font-family: "GilroyThree";
          margin-bottom: 1rem;
        }
      }

      h1 {
        font-size: 37.049px;
        font-weight: 900;
        color: #19b01f;
        margin: 0rem 0 1rem 0;
        font-family: "GilroySeven";
      }

      button {
        padding: 0.5rem 2rem;
        border: none;
        outline: none;
        background-color: #19b01f;
        border-radius: 27px;
        margin-top: 0.5rem;
        transition: 0.5s ease;

        a {
          text-decoration: none;
          color: #fff;
          font-weight: 600;
          font-family: "GilroyFour";
        }

        &:hover {
          background-color: #fff;
          box-shadow: 1px 1px 10px #19b01e2f;
          cursor: pointer;

          a {
            color: #19b01f;
          }
        }
      }
    }
  }
}

.res_home_second_section {
  width: 100%;
  height: 80vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .power_to_empower {
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1;
    // border: 2px solid blue;

    .power_heading {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 80%;
        height: 100%;
      }
    }

    .power_video_wrapper {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      video {
        width: 90%;
        height: 90%;
        object-fit: cover;
        border-radius: 15px;
      }
    }
  }

  .redident-solar-downward {
    position: absolute;
    height: 40%;
    bottom: 0;
    width: 100%;
    z-index: -1;
    overflow: hidden;

    video {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  .resident-solar-revolution {
    // border: 1px solid blue;
    width: 100%;
    height: 35rem !important;
    position: relative;
    margin-top: 1.5rem;
  
    .resident-solar-evo-container {
      z-index: -1;
      width: 100%;
      height: 30%;
      overflow: hidden;
  
      video {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  
    .image-residental {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      z-index: 1;
      gap: 40px;
      // background-color: blue;
      padding: 0;
  
      .image_wrapper {
        width: 100%;
        height: 40%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0 1rem;
  
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          overflow: hidden;
          border-radius: 15px;
          margin-left: -1rem;
          margin-bottom: 4rem;
        }
  
        .dark_overlay {
          position: absolute;
          bottom: 2%;
          left: 10%;
          background-color: #fff;
          border-radius: 10px;
          width: 203.345px;
          height: 106.897px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 12.618px 17.813px 0px rgba(0, 0, 0, 0.08);
  
          h1 {
            font-size: 41.721px;
            font-weight: 800;
            margin-bottom: 0;
            color: #19b01f;
          }
  
          p {
            margin: 0;
            color: #656565;
            font-size: 11.561px;
            font-weight: 300;
            text-transform: capitalize;
          }
        }
      }
  
      .content_container {
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0 2rem 0 2rem;
        margin-top: -4rem;
  
        p {
          font-size: 13px;
          font-weight: 300;
          color: black;
          font-family: "GilroyFive";
          margin: 0;
          margin-bottom: 0.2rem;
          text-align: center;
  
          &:nth-child(3) {
            font-weight: 500;
            font-family: "GilroyThree";
            margin-bottom: 0.5rem;
          }
        }
  
        h1 {
          font-size: 28px;
          font-weight: 900;
          color: #19b01f;
          margin: 0rem 0 1rem 0;
          font-family: "GilroySeven";
          text-align: center;
        }
  
        button {
          padding: 0.3rem 2rem;
          border: none;
          outline: none;
          background-color: #19b01f;
          border-radius: 27px;
          margin-top: 0.5rem;
          transition: 0.5s ease;
  
          a {
            text-decoration: none;
            color: #fff;
            font-weight: 600;
            font-family: "GilroyFour";
          }
  
          &:hover {
            background-color: #fff;
            box-shadow: 1px 1px 10px #19b01e2f;
            cursor: pointer;
  
            a {
              color: #19b01f;
            }
          }
        }
      }
    }
  }
  
  .res_home_second_section {
    width: 100%;
    height: 90vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .power_to_empower {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      z-index: 1;
      // border: 2px solid blue;
  
      .power_heading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
  
        img {
          width: 80%;
          height: 100%;
        }
      }
  
      .power_video_wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
  
        video {
          width: 90%;
          height: 90%;
          object-fit: cover;
          border-radius: 15px;
        }
      }
    }
  
    .redident-solar-downward {
      position: absolute;
      height: 40%;
      bottom: 0;
      width: 100%;
      z-index: -1;
      overflow: hidden;
  
      video {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* CSS styles here */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* CSS styles here */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* CSS styles here */
}
