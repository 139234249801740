* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Hide the increment and decrement buttons */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type="number"] {
    /* Optional: Ensure the input looks consistent across browsers */
    -moz-appearance: textfield;
    appearance: textfield;
  }
  
  #exampleModal {
    .modal-body {
      width: 100%;
      height: max-content;
      border-radius: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 1.5rem 3.5rem;
      width: 600px;
  
      .close_btn_container {
        width: calc(100% + 3.5rem);
        height: max-content;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        // background-color: orange;
        padding: 0;
  
        button {
          border: none;
          outline: none;
          background: transparent;
          font-size: 32px;
          margin-top: -15px;
          padding: 0;
          // background-color: red;
        }
      }
  
      .heading_container {
        width: 100%;
        height: max-content;
        margin-top: 0px;
        align-self: flex-start;
        // background-color: orange;
  
        p {
          margin: 0;
          padding: 0;
          color: #000;
          font-size: 22.885px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
  
      .detail_form {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1.5rem;
  
        input {
          width: 100%;
          border: none;
          outline: none;
          border-bottom: 2px solid #19b01f;
          margin-bottom: 1.5rem;
          padding: 0.5rem 0.2rem;
        }
  
        input[type="submit"] {
          padding: 12px 42px;
          border-radius: 10.676px;
          background: #19b01f;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          width: 35%;
          align-self: flex-start;
        }
      }
    }
  }
  
  .products_range {
    width: 100%;
    height: 43rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    // overflow: hidden;
    margin: 2.5rem 0 2.5rem 0;
  
    .products_range_heading {
      width: 100%;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 2rem;
  
      h1 {
        font-size: 36px;
        font-weight: 800;
        margin-top: 2.5rem;
      }
    }
  
    .products_range_wrapper {
      width: 80%;
      height: 38rem;
      background-color: #f4f4f4;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2.5rem;
      position: relative;
  
      .get_latest_updates_on_products {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        box-shadow: 0px 3.143px 3.143px 0px rgba(25, 176, 31, 0.1);
        padding: 0.4rem 1rem;
        border-radius: 10px;
        background-color: #18af1e;
        border: none;
        outline: none;
        color: #fff;
        transition: 0.5s;
  
        &:hover {
          cursor: pointer;
          color: #18af1e;
          background-color: #fff;
        }
      }
  
      .display_image_container {
        width: 45%;
        height: 100%;
        padding-right: 2.5rem;
  
        .main_image {
          width: 100%;
          height: 75%;
          background-color: #fff;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0rem;
          overflow: hidden !important;
  
          img {
            width: 100%;
            // height: 100%;
            // object-fit: cover;
          }
        }
  
        .change_main_image_buttons {
          width: 100%;
          height: 25%;
          display: flex;
          align-items: flex-end;
          justify-content: space-around;
  
          .img_chng_btn {
            height: 6rem;
            width: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            border: 1px solid #000;
            outline: none;
            background-color: white;
            overflow: hidden;
  
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
  
        // img {
        //     width: 100px;
        // }
      }
  
      .product_details_wrapper {
        width: 55%;
        height: 100%;
        padding-left: 2.5rem;
        padding-right: 1rem;
  
        .change_product_buttons {
          width: 100%;
          max-height: 20%;
          // overflow: scroll;
          display: flex;
          gap: 1rem;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-left: -1rem;
          padding-left: 1rem;
          margin-bottom: 1rem;
  
          input[type="radio"] {
            display: none;
  
            &:checked + label {
              background-color: #e31e24;
              color: #fff;
              // box-shadow: 0px 0px 10px #e31e25c9;
            }
          }
  
          label {
            border: none;
            color: #525252;
            background-color: #fff;
            padding: 0.4rem 1.5rem;
            border-radius: 10px;
            font-weight: 800;
  
            &:hover {
              cursor: pointer;
            }
          }
        }
  
        .description {
          width: 100%;
          max-height: 60%;
          // overflow: scroll;
  
          h3 {
            font-size: 26px;
            color: #19b01f;
            font-weight: 800;
          }
  
          p {
            font-size: 14px;
            font-weight: 600;
          }
        }
  
        .cta_buttons {
          width: 100%;
          max-height: 20%;
          margin-top: 2rem;
          display: flex;
          gap: 1.5rem;
  
          .cta {
            border: none;
            background-color: #fff;
            padding: 0.5rem 1rem;
            border-radius: 10px;
            font-weight: 700;
            box-shadow: 0px 4px 4px 0px rgba(25, 176, 31, 0.1);
  
            a {
              text-decoration: none;
              color: #525252;
            }
          }
        }
      }
    }
  }
  
  
  @media (max-width: 575.98px) {
  
    .products_range {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      overflow: visible;
      margin: 2.5rem 0 2.5rem 0;
  
      .products_range_heading {
        width: 100%;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 2rem;
        padding: 0 2.5rem;
  
        h1 {
          font-size: 26px;
          font-weight: 800;
        }
      }
  
      .products_range_wrapper {
        width: 95%;
        height: max-content;
        background-color: #f4f4f4;
        border-radius: 20px;
        display: flex;
        gap: 2.5rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;
        padding-bottom: 2.5rem;
  
        .display_image_container {
          width: 100%;
          height: 100%;
          padding-right: 0.5rem;
  
          .main_image {
            width: 100%;
            height: 20rem;
            background-color: #fff;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0rem;
            overflow: hidden;
            margin-bottom: 1rem;
  
            img {
              width: 100%;
              height: auto;
              object-fit: cover;
            }
          }
  
          .change_main_image_buttons {
            width: 100%;
            height: 25%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            gap: 1.5rem;

            .img_chng_btn {
              height: 3.5rem;
              width: 3.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              border: 1px solid #000;
              outline: none;
              background-color: white;
  
              img {
                width: 90%;
                object-fit: cover;
              }
            }
          }
  
          // img {
          //     width: 100px;
          // }
        }
  
        .product_details_wrapper {
          width: 100%;
          height: 100%;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
  
          .change_product_buttons {
            width: 100%;
            max-height: 20%;
            // overflow: scroll;
            display: flex;
            gap: 0.6rem;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-left: -1rem;
            padding-left: 1rem;
            margin-bottom: 1rem;
  
            input[type="radio"] {
              display: none;
  
              &:checked + label {
                background-color: #e31e24;
                color: #fff;
                // box-shadow: 0px 0px 10px #e31e25c9;
              }
            }
  
            label {
              border: none;
              color: #525252;
              background-color: #fff;
              padding: 0.4rem 1rem;
              border-radius: 10px;
              font-weight: 800;
              font-size: 12px;
  
              &:hover {
                cursor: pointer;
              }
            }
          }
  
          .description {
            width: 100%;
            max-height: 60%;
            // overflow: scroll;
  
            h3 {
              font-size: 26px;
              color: #19b01f;
              font-weight: 800;
            }
  
            p {
              font-size: 14px;
              font-weight: 600;
            }
          }
  
          .cta_buttons {
            width: 100%;
            max-height: 20%;
            margin-top: 2rem;
            display: flex;
            gap: 1.5rem;
  
            .cta {
              border: none;
              background-color: #fff;
              padding: 0.5rem 1rem;
              border-radius: 10px;
              font-weight: 700;
              box-shadow: 0px 4px 4px 0px rgba(25, 176, 31, 0.1);
              font-size: 14px;
  
              a {
                text-decoration: none;
                color: #525252;
              }
            }
          }
        }
      }
    }
  }
  