* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.faqs {
    width: 100%;
    height: max-content;

    .top_banner {
        width: 100%;
        height: 30rem;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        .overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding-left: 15%;
            color: #fff;

            h1 {
                font-size: 52px;
                font-weight: 500;
                margin-bottom: 2rem;
            }

            p {
                font-size: 48px;
                font-weight: 800;
            }
        }
    }

    .main_image_wrapper {
        width: 100%;
        height: 32rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 45%;
        }
    }

    .questions_wrapper {
        width: 100%;
        height: max-content;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 2rem 0;
        padding-bottom: 5rem;

        .accordion {
            width: 80%;

            .accordion-item {
                margin-bottom: 2.5rem;
                outline: none;
                box-shadow: 1px 1px 10px #0000001c;
                background-color: #fff;
                border-radius: 5px;

                .accordion-header {
                    .accordion-button {
                        outline: none;
                        border: none;
                        text-decoration: underline;
                        font-weight: 600;
                        border-top-right-radius: 5px;
                        border-top-left-radius: 5px;
                    }
                }
            }
        }
    }
}