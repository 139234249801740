* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.res_fourth_section_wrapper {
  width: 100%;
  height: max-content;
  background-color: #e7ffeb;
  padding-bottom: 2.5rem;
  overflow: hidden;

  .res_fourth_section {
    width: 100%;
    height: max-content;
    padding: 2.5rem 5rem;
    background-color: #fff;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    overflow: hidden;

    .featured_products {
      width: 100%;
      height: max-content;
      padding-bottom: 7.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      h1 {
        font-size: 34px;
        font-weight: 900;
        color: #19b01f;
        text-transform: uppercase;
        margin-bottom: 2.5rem;
        font-family: "GilroySeven";
        letter-spacing: 2px;
      }

      .product_list {
        width: 100%;
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: space-around;
        overflow: hidden;
        .product_wrapper {
          width: 250px;
          height: 300px;
          border-radius: 4.556px;
          position: relative;
          filter: drop-shadow(0px 4px 25px rgba(25, 176, 31, 0.3));

          .product {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            overflow: hidden;
            position: relative;
            z-index: 2;

            img {
              width: 100%;
              height: 100%;
              overflow: hidden;
            }

            .contents {
              width: 100%;
              height: 100%;
              position: absolute;
              z-index: 999999999999;
              top: 0;
              left: 0;
              padding: 1.5rem 0 0 1.5rem;

              h1 {
                font-size: 20px;
                color: #fff;
                font-weight: 700;
                margin: 0;
                margin-bottom: 0.2rem;
                font-family: "GilroyFive";
                letter-spacing: 0;
              }

              p {
                font-size: 12px;
                color: #fff;
                font-weight: 400;
                margin: 0;
                margin-bottom: 0.5rem;
                font-family: "GilroyThree";
              }

              button {
                border: none;
                outline: none;
                background-color: #19b01f;
                border-radius: 5px;
                padding: 0.3rem 1rem;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                  font-size: 11px;
                  text-decoration: none;
                  color: #fff;
                  font-weight: 700;
                  font-family: "GilroyFour";
                }
              }
            }
          }

          // .overlay {
          //   width: 113%;
          //   height: 75%;
          //   position: absolute;
          //   bottom: -5%;
          //   left: -5%;
          //   background-color: #19b01f;
          //   z-index: 1;
          //   border-top-left-radius: 25px;
          //   border-bottom-left-radius: 15px;
          //   border-bottom-right-radius: 15px;
          // }
        }
      }
    }

    .search_service {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;

      .area_image {
        width: 50%;
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 65%;
        }
      }

      .search_box_wrapper {
        width: 50%;
        height: max-content;
        padding: 0 5rem;
        overflow: hidden;

        h1 {
          font-weight: 900;
          text-transform: uppercase;
          margin-bottom: 2.5rem;
          color: #19b01f;
        }

        .search_input_wrapper {
          width: 100%;
          height: max-content;
          display: flex;
          gap: 1rem;
          margin-top: 1rem;

          .search_input {
            width: 65%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border: 1px solid #fff;
            padding-left: 0.5rem;
            border-radius: 5px;
            background-color: lightgray;

            .loc_icon {
              font-size: 24px;
              color: black;
              // background: lightgray;
            }

            input {
              font-size: 16px;
              padding: 0.7rem 0.5rem;
              width: 100%;
              border: none;
              outline: none;
              background: lightgray;
            }
          }

          .search_button {
            width: 25%;
            border-radius: 5px;
            border: none;
            font-size: 16px;
            font-weight: 700;
            color: #fff;
            background-color: #19b01f;
            transition: 0.5s ease;

            &:hover {
              cursor: pointer;
              background-color: #fff;
              color: #19b01f;
              box-shadow: 1px 1px 5px #19b01e78;
            }
          }
        }
      }
    }

    .connect_and_partner {
      width: 100%;
      height: max-content;
      padding: 5rem 5rem;
      display: flex;
      gap: 5rem;
      align-items: center;
      justify-content: center;

      .cpcard {
        width: 40%;
        height: 300px;
        border: 1px solid #19b01f;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 3rem;
        transition: 0.5s ease;

        h1 {
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 0.5rem;
          font-family: "GilroySix";
        }

        h5 {
          font-family: "GilroyFour";
          text-align: center;
          font-size: 22px;
        }

        button {
          padding: 0.5rem 2rem;
          border: none;
          outline: none;
          background-color: #19b01f;
          border-radius: 5px;
          margin-top: 1.5rem;
          transition: 0.5s ease;

          a {
            text-decoration: none;
            color: #fff;
            font-weight: 600;
          }

          &:hover {
            background-color: #fff;
            box-shadow: 1px 1px 5px #19b01e53;
            cursor: pointer;

            a {
              color: #19b01f;
            }
          }
        }

        &:hover {
          background-color: #19b01f;
          color: #fff;

          button {
            background-color: #fff;

            a {
              color: #19b01f;
            }
          }
        }
      }

      .partner {
        padding: 0 2rem;
      }
    }
  }
}


/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  .res_fourth_section_wrapper {
    width: 100%;
    height: max-content;
    background-color: #e7ffeb;
    padding-bottom: 2.5rem;
    overflow: hidden;
  
    .res_fourth_section {
      width: 100%;
      height: max-content;
      padding: 2.5rem 0rem;
      background-color: #fff;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      overflow: hidden;

      .featured_products {
        width: 100%;
        height: max-content;
        padding-bottom: 2.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
  
        h1 {
          font-size: 28px;
          font-weight: 900;
          color: #19b01f;
          text-transform: uppercase;
          margin-bottom: 1.5rem;
          font-family: "GilroySeven";
          letter-spacing: 2px;
        }
  
        .product_list {
          width: 100%;
          height: max-content;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: center;
          justify-content: space-around;
          overflow: hidden;
          .product_wrapper {
            width: 250px;
            height: 300px;
            border-radius: 4.556px;
            position: relative;
            filter: drop-shadow(0px 1px 5px rgba(25, 176, 31, 0.3));
  
            .product {
              width: 100%;
              height: 100%;
              border-radius: 5px;
              overflow: hidden;
              position: relative;
              z-index: 2;
  
              img {
                width: 100%;
                height: 100%;
                overflow: hidden;
              }
  
              .contents {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 999999999999;
                top: 0;
                left: 0;
                padding: 1.5rem 0 0 1.5rem;
  
                h1 {
                  font-size: 20px;
                  color: #fff;
                  font-weight: 700;
                  margin: 0;
                  margin-bottom: 0.2rem;
                  font-family: "GilroyFive";
                  letter-spacing: 0;
                }
  
                p {
                  font-size: 12px;
                  color: #fff;
                  font-weight: 400;
                  margin: 0;
                  margin-bottom: 0.5rem;
                  font-family: "GilroyThree";
                }
  
                button {
                  border: none;
                  outline: none;
                  background-color: #19b01f;
                  border-radius: 5px;
                  padding: 0.3rem 1rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
  
                  a {
                    font-size: 11px;
                    text-decoration: none;
                    color: #fff;
                    font-weight: 700;
                    font-family: "GilroyFour";
                  }
                }
              }
            }
  
            // .overlay {
            //   width: 113%;
            //   height: 75%;
            //   position: absolute;
            //   bottom: -5%;
            //   left: -5%;
            //   background-color: #19b01f;
            //   z-index: 1;
            //   border-top-left-radius: 25px;
            //   border-bottom-left-radius: 15px;
            //   border-bottom-right-radius: 15px;
            // }
          }
        }
      }
  
      .connect_and_partner {
        width: 100%;
        height: max-content;
        padding: 2.5rem 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
        justify-content: center;
  
        .cpcard {
          width: 100%;
          height: 300px;
          border: 1px solid #19b01f;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 1.3rem;
          transition: 0.5s ease;
  
          h1 {
            font-size: 22px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 0.5rem;
            font-family: "GilroySix";
          }
  
          h5 {
            font-family: "GilroyFour";
            text-align: center;
            font-size: 18px;
          }
  
          button {
            padding: 0.5rem 2rem;
            border: none;
            outline: none;
            background-color: #19b01f;
            border-radius: 5px;
            margin-top: 1.5rem;
            transition: 0.5s ease;
  
            a {
              text-decoration: none;
              color: #fff;
              font-weight: 600;
            }
  
            &:hover {
              background-color: #fff;
              box-shadow: 1px 1px 5px #19b01e53;
              cursor: pointer;
  
              a {
                color: #19b01f;
              }
            }
          }
  
          &:hover {
            background-color: #19b01f;
            color: #fff;
  
            button {
              background-color: #fff;
  
              a {
                color: #19b01f;
              }
            }
          }
        }
  
        .partner {
          padding: 0 2rem;
        }
      }
    }
  }
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* CSS styles here */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* CSS styles here */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* CSS styles here */
}
