* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer_wrapper {
  width: 100%;
  height: max-content;
  background-color: #e7ffec;
  color: #233121;

  .subscribe {
    width: 100%;
    height: 13rem;
    background-color: #e7ffec;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid grey;
    .subscribe-container {
      width: 80%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;

      h3 {
        font-family: "PoppinsFour";
        font-size: 24px;
        margin: 0;
      }

      p {
        font-family: "PoppinsThree";
        font-size: 18px;
        margin: 0 0 0.5rem 0;
        padding: 0;
      }

      .subsribe-input {
        width: 100%;
        height: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;

        input {
          width: 32%;
          background-color: #e7ffec;
          border-radius: 7.523px;
          border: 0.94px solid #19b01f;
          outline: none;
          font-size: 14px;
          padding: 0.7rem;
          color: #19b01f;
          font-family: "PoppinsThree";
          // box-shadow: 0.5px 0.5px 2px #19B01F;
          &::placeholder {
            color: #19b01f;
          }
        }

        .btn {
          width: 12%;
          background-color: #19b01f;
          color: white;
          font-size: 16px;
          padding: 0.6rem;
        }

        .subscribe-button {
          button {
            background-color: #19b01f;
            color: white;
          }
        }
      }
    }
  }

  .footer {
    width: 100%;
    height: max-content;
    // border-bottom: 2px solid #233121;
    display: flex;
    align-items: center;
    // border: 2px solid red;
    justify-content: center;
    // padding: 4rem 5rem;
    .footer-container {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 4rem 2rem 4rem 5rem;

      .logos_social_icons_wrapper,
      .product_line_wrapper,
      .our_pages_wrapper,
      .factory_address_wrapper,
      .get_in_touch_wrapper {
        width: 20%;
        height: 100%;
        // border: 2px solid green;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 1rem;

        // h1 {
        //   color: #233121;
        //   font-family: "GothamSix";
        //   font-size: 18px !important;
        // }
      }

      .logos_social_icons_wrapper {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .company_logo_wrapper {
          width: 100%;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          margin: 0;
          padding: 0;

          a {
            width: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 55%;
            }
          }
        }

        .social_icons {
          width: 100%;
          height: max-content;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1.5rem;
          padding-top: 1.5rem;
          // background-color: orange;
          padding-right: 1.5rem;

          li {
            list-style-type: none;
            padding-bottom: 0.5rem;

            a {
              text-decoration: none;
              color: #233121;
              font-size: 24px;
              font-weight: 700;
            }
          }
        }
      }

      .product_line_wrapper,
      .our_pages_wrapper {
        width: 20%;
        height: 100%;
        padding-left: 1.5rem;

        h1 {
          font-family: "GothamSix";
          font-size: 18px !important;
          text-transform: uppercase;
          margin-bottom: 1rem;
        }

        ul {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          padding-left: 0;

          li {
            list-style-type: none;

            a {
              text-decoration: none;
              color: #233121;
              font-weight: 600;
            }
          }
        }
      }

      .factory_address_wrapper {
        width: 30%;
        padding-right: 3rem;
        // border: 3px solid blue;
        h1 {
          font-family: "GothamSix";
          font-size: 18px !important;
          text-transform: uppercase;
          margin-bottom: 1rem;

          &:nth-child(3) {
            margin-top: 1rem;
            margin-bottom: 1rem;
            // color: #19B01F;
          }
        }

        p {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 1rem;
        }
      }

      .get_in_touch_wrapper {
        margin-right: 0;
        width: 30%;
        // border: 1px solid red;
        h1 {
          font-family: "GothamSix";
          font-size: 18px !important;
          text-transform: uppercase;
          margin-bottom: 0.9rem;
        }

        p {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 1.5rem;
        }

        .toll_free {
          font-size: 18px;
          font-family: "GothamSix";
          margin-bottom: 0.7rem;
          margin-top: 1.6rem;
          color: #233121;
        }
      }
    }

    .footer-container-mobile {
      width: 100%;
      display: none;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 2rem 2.5rem;

      .logos_social_icons_wrapper,
      .product_line_wrapper,
      .our_pages_wrapper,
      .factory_address_wrapper,
      .get_in_touch_wrapper {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-right: 0rem;
        text-align: center;

        h1 {
          font-family: "GothamSix";
          font-size: 18px !important;
          text-transform: uppercase;
          margin-bottom: 1.5rem;
        }
      }

      .logos_social_icons_wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1rem;

        .company_logo_wrapper {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          margin: 0;
          padding: 0;

          a {
            img {
              width: 100%;
            }
          }
        }

        .social_icons {
          width: 100%;
          height: max-content;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1.5rem;
          padding-top: 1.5rem;
          // background-color: orange;
          padding-right: 1.5rem;

          li {
            list-style-type: none;
            padding-bottom: 0.5rem;

            a {
              text-decoration: none;
              color: #233121;
              font-size: 24px;
              font-weight: 700;
            }
          }
        }
      }

      .product_line_wrapper,
      .our_pages_wrapper {
        width: 100%;
        height: max-content;
        padding-left: 0rem;
        text-align: center;

        h1 {
          font-family: "GothamSix";
          font-size: 18px !important;
          text-transform: uppercase;
          margin-bottom: 1.5rem;
        }

        ul {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding-left: 0;

          li {
            list-style-type: none;

            a {
              text-decoration: none;
              color: #233121;
              font-weight: 600;
            }
          }
        }
      }

      .factory_address_wrapper {
        width: 100%;
        padding-right: 0rem;
        h1 {
          font-family: "GothamSix";
          font-size: 18px !important;
          text-transform: uppercase;
          margin-bottom: 1rem !important;

          &:nth-child(3) {
            margin-top: 1rem;
            margin-bottom: 1rem;
            // color: #19B01F;
          }
        }

        p {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 1rem;
        }
      }

      .get_in_touch_wrapper {
        margin-right: 0;
        width: 100%;
        // border: 1px solid red;
        h1 {
          font-size: 20px;
          text-transform: uppercase;
          margin-bottom: 1rem;
        }

        p {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 1.5rem;
        }

        .toll_free {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 0.7rem;
          margin-top: 1.6rem;
          color: #233121;
        }
      }
    }

    .copyright {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;

      p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0 !important;
      }
    }
  }
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 991.98px) {
  .footer_wrapper {
    .subscribe {
      align-items: flex-start;
      .subscribe-container {
        align-items: center;
        flex-direction: column;

        h3 {
          font-size: 18px;
          margin: 0;
        }

        p {
          font-size: 12px;
          margin: 0;
          text-align: center;
        }

        .subsribe-input {
          width: 100%;
          height: max-content;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 15px;
  
          input {
            width: 80%;
            background-color: #e7ffec;
            border-radius: 7.523px;
            border: 0.94px solid #19b01f;
            outline: none;
            font-size: 14px;
            padding: 0.7rem;
            color: #19b01f;
            font-family: "PoppinsThree";
            // box-shadow: 0.5px 0.5px 2px #19B01F;
            &::placeholder {
              color: #19b01f;
            }
          }
  
          .btn {
            width: 40%;
            background-color: #19b01f;
            color: white;
            font-size: 14px;
            padding: 0.6rem;
          }
  
          .subscribe-button {
            button {
              background-color: #19b01f;
              color: white;
            }
          }
        }
      }
    }

    .footer {
      width: 100%;
      height: max-content;
      // border-bottom: 2px solid #233121;
      display: flex;
      align-items: center;
      // border: 2px solid red;
      justify-content: center;
      // padding: 4rem 5rem;
      .footer-container {
        display: none;
      }

      .footer-container-mobile {
        display: flex;

        .logos_social_icons_wrapper {
          .company_logo_wrapper {
            a {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 50%;
              }
            }
          }
        }
      }

      .copyright {
        width: 100%;
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 0;

        p {
          font-size: 12px !important;
          font-weight: 600;
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .footer {
    .footer_wrapper {
      .footer-container {
        padding: 4rem 2.5rem;
        // background-color: #19b01f !important;
      }
    }
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* CSS styles here */
}
