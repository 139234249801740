* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.commercial_home_first_section {
  height: 86vh;
  width: 100%;
  overflow: hidden;
  position: relative;

  .overlay {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    // background-color: #18af1d2c;
    background: linear-gradient(
        90deg,
        rgba(25, 176, 31, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
    mix-blend-mode: multiply;
  }

  video {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
    transform: scaleX(-1);
  }

  .first_sec_contents {
    width: 80%;
    height: 100%;
    // background-color: red;
    padding-left: 10rem;
    padding-right: 5rem;
    // padding-bottom: 12rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #fff;

    p {
      font-size: 22px;
      font-weight: 400;
      // margin-bottom: 0;
      font-family: "PoppinsThree";
    }

    h1 {
      font-size: 50px;
      text-transform: capitalize;
      font-weight: 800;
      margin-bottom: 0;
    }
  }
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  .commercial_home_first_section {
    height: 50vh;
    width: 100%;
    overflow: hidden;
    position: relative;
  
    .overlay {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      // background-color: #18af1d2c;
      background: linear-gradient(
          90deg,
          rgba(25, 176, 31, 0.2) 0%,
          rgba(0, 0, 0, 0.2) 100%
        ),
        linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
      mix-blend-mode: multiply;
    }
  
    video {
      position: absolute;
      top: 0;
      height: 100%;
      width: auto;
      z-index: -2;
      transform: scaleX(-1);
    }
  
    .first_sec_contents {
      width: 100%;
      height: 100%;
      // background-color: red;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      // padding-bottom: 12rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      color: #fff;
  
      p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0;
        font-family: "PoppinsThree";
      }
  
      h1 {
        font-size: 40px;
        text-transform: capitalize;
        font-weight: 800;
        margin-bottom: 0;
      }
    }
  }
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* CSS styles here */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* CSS styles here */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* CSS styles here */
}

