* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.product_third_section_wrapper {
  width: 100%;
  height: max-content;
  background-color: #121212;
  padding-bottom: 2.5rem;

  .product_fourth_section {
    width: 100%;
    height: max-content;
    padding: 2.5rem 5rem;
    background-color: #fff;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;

    .featured_products {
      width: 100%;
      height: max-content;
      //   padding-bottom: 7.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 34px;
        font-weight: 900;
        color: #6F6F6F;
        margin-bottom: 2.5rem;
      }

      .product_list {
        width: 100%;
        height: max-content;
        display: flex;
        gap: 2.5rem;
        align-items: center;
        justify-content: center;

        .product {
          width: 200px;
          height: 300px;
          border-radius: 0px;
          overflow: hidden;

          .name {
            width: 100%;
            height: max-content;
            background-color: #FBBA00;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.3rem 0;
            border-radius: 5px;
            margin-bottom: 1rem;

            p {
              font-size: 14px;
              font-weight: 700;
              margin-bottom: 0;
              color: #fff;
            }
          }

          .info {
            width: 100%;
            height: max-content;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            background-color: #F2F2F2;
            border-radius: 5px;
            padding: 1.5rem 0;

            button {
              padding: 0.3rem 1rem;
              border: none;
              outline: none;
              background-color: #19B01F;
              border-radius: 5px;

              a {
                text-decoration: none;
                color: #fff;
                font-size: 14px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    .detailed_product_info_wrapper {
      width: 100%;
      height: 80vh;
      display: flex;
      align-items: center;
      justify-content: center;

      .detailed_product_info {
        width: 75%;
        height: 75%;
        background-color: #E7E7E7;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;

        .product_image {
          width: 40%;
          height: 100%;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          background-image: linear-gradient(to top, #E7E7E7, #fff);

          img {
            width: 70%;
          }
        }

        .product_info {
          width: 60%;
          height: 100%;
          background-color: #19B01F;
          border-radius: 10px;
          padding: 5rem 2.5rem;

          .product_chng_button_wrapper {
            width: 100%;
            height: max-content;
            display: flex;
            gap: 2rem;
            align-items: center;
            justify-content: flex-start;

            button {
              height: max-content;
              background-color: #E31E24;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.3rem 2rem;
              border-radius: 5px;
              margin-bottom: 1rem;
              border: none;
              outline: none;

              &:nth-child(2) {
                background-color: #fff;

                a {
                  color: #525252;
                }
              }

              a {
                font-size: 18px;
                font-weight: 900;
                margin-bottom: 0;
                color: #fff;
                text-decoration: none;
              }
            }
          }

          .all_information {
            margin-top: 1.5rem;

            h1 {
              font-size: 22px;
              color: #fff;
            }

            p {
              font-size: 14px;
              color: #fff;
            }

            button {
              padding: 0.3rem 1rem;
              border: none;
              outline: none;
              background-color: #fff;
              border-radius: 5px;
              margin-top: 1.5rem;

              a {
                text-decoration: none;
                color: #776969;
                font-size: 14px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    .search_service {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;

      .area_image {
        width: 50%;
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 65%;
        }
      }

      .search_box_wrapper {
        width: 50%;
        height: max-content;
        padding: 0 5rem;
        overflow: hidden;

        h1 {
          font-weight: 900;
          text-transform: uppercase;
          margin-bottom: 2.5rem;
          color: #19b01f;
        }

        .search_input_wrapper {
          width: 100%;
          height: max-content;
          display: flex;
          gap: 1rem;
          margin-top: 1rem;

          .search_input {
            width: 65%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border: 1px solid #fff;
            padding-left: 0.5rem;
            border-radius: 5px;
            background-color: lightgray;

            .loc_icon {
              font-size: 24px;
              color: black;
            }

            input {
              font-size: 16px;
              padding: 0.7rem 0.5rem;
              width: 100%;
              border: none;
              outline: none;
              background: lightgray;
              color: black;
            }
          }

          .search_button {
            width: 25%;
            border-radius: 5px;
            border: none;
            font-size: 16px;
            font-weight: 700;
            color: #fff;
            background-color: #19b01f;
            transition: 0.5s ease;

            &:hover {
              cursor: pointer;
              background-color: #fff;
              color: #19b01f;
              box-shadow: 1px 1px 5px #19b01e78;
            }
          }
        }
      }
    }
  }
}