* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.all_nearby_dealers_wrapper {
  width: 100%;
  height: max-content;
  background-color: #121212;

  .all_nearby_dealers {
    width: 100%;
    height: max-content;
    background-color: #fff;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 0 5rem 5rem 5rem;

    .dealers_heading {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;

      h1 {
        font-size: 34px;
        font-weight: 700;
        color: #19b01f;
        text-align: center;
        margin-top: 2rem;
      }

    .search_input_wrapper {
      width: 40%;
      height: max-content;
      display: flex;
      gap: 1rem;
      margin-top: 1rem;

      .search_input {
        width: 65%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        border: 1px solid #000;
        padding-left: 0.5rem;
        border-radius: 5px;
        // background-color: rgba(0, 0, 0, 0.189);
        // overflow: scroll;

        .loc_icon {
          font-size: 24px;
          margin-top: 0.6rem;
        }

        input {
          font-size: 16px;
          padding: 0.7rem 0.5rem;
          width: 100%;
          border: none;
          outline: none;
          background: transparent;
          color: #000;
        }
      }

      .search_button {
        width: 25%;
        height: 2.8rem;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        background-color: #19b01f;
        transition: 0.5s ease;

        &:hover {
          cursor: pointer;
          background-color: #fff;
          color: #19b01f;
        }
      }
    }
    }

    .dealers_container {
      width: 100%;
      height: max-content;
      display: flex;
      flex-wrap: wrap;
      gap: 5rem;
      align-items: flex-start;
      justify-content: flex-start;

      .dealer {
        width: 350px;
        height: max-content;
        // height: 425px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 2rem;
        // border: 2px solid #000;
        overflow: scroll;
        border-radius: 27px;
        box-shadow: 1px 1px 25px #19b01e5e;

        .profile_image_container {
          width: 150px;
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          // border: 2px solid black;
          margin-bottom: 1.5rem;
          overflow: hidden;

          img {
            width: 100%;
          }
        }

        .profile_content_container {
          width: 100%;
          height: max-content;

          .name,
          .email,
          .phone,
          .address {
            display: flex;
            gap: 0.7rem;
            align-items: center;
            justify-content: flex-start;
            // border: 1px solid green;
            padding: 0;
            margin-bottom: 1rem;

            span {
              font-size: 20px;
              color: #19b01f;
            }

            p {
              margin: 0;
              font-size: 15px;
              font-weight: 600;
            }
          }
        }

        .send_msg_btn {
          margin-top: 1rem;
          padding: 0.7rem 2rem;
          border: none;
          outline: none;
          background-color: #19b01f;
          color: #fff;
          border-radius: 10px;

          .send_msg_icon {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}
