* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.commercial_fourth_section_wrapper {
  width: 100%;
  height: max-content;
  background-color: #e7ffeb;
  padding-bottom: 2.5rem;

  .res_fourth_section {
    width: 100%;
    height: max-content;
    padding: 2.5rem 5rem;
    background-color: #fff;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    padding-bottom: 7.5rem;

    .featured_products {
      width: 100%;
      height: max-content;
      padding-bottom: 7.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 34px;
        font-weight: 900;
        color: #19b01f;
        text-transform: uppercase;
        margin-bottom: 2.5rem;
      }

      .product_list {
        width: 100%;
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .product_wrapper {
          width: 250px;
          height: 300px;
          border-radius: 5px;
          position: relative;

          .product {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            overflow: hidden;
            position: relative;
            z-index: 2;

            img {
              width: 100%;
              height: 100%;
            }

            .contents {
              width: 100%;
              height: 100%;
              position: absolute;
              z-index: 999999999999;
              top: 0;
              left: 0;
              padding: 1.5rem 0 0 1.5rem;

              h1 {
                font-size: 20px;
                color: #fff;
                font-weight: 700;
                margin: 0;
                margin-bottom: 0.5rem;
              }

              p {
                font-size: 12px;
                color: #fff;
                font-weight: 400;
                margin: 0;
                margin-bottom: 0.9rem;
              }

              button {
                border: none;
                outline: none;
                background-color: #19b01f;
                border-radius: 5px;
                padding: 0.3rem 1rem;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                  font-size: 11px;
                  text-decoration: none;
                  color: #fff;
                  font-weight: 700;
                }
              }
            }
          }

          // .overlay {
          //   width: 113%;
          //   height: 75%;
          //   position: absolute;
          //   bottom: -5%;
          //   left: -5%;
          //   background-color: #19b01f;
          //   z-index: 1;
          //   border-top-left-radius: 25px;
          //   border-bottom-left-radius: 15px;
          //   border-bottom-right-radius: 15px;
          // }
        }
      }
    }

    .Partner_with_us {
      width: 75%;
      height: 20rem;
      display: flex;
      align-items: center;
      margin: auto;
      justify-content: space-between;

      .partner_image {
        width: 50%;
        height: 100%;
        // background-color: orange;
        border-radius: 30px;
        box-shadow: -10px 10px 0 #19b01f;
        padding: 0;
        position: relative;
        overflow: hidden;

        img {
          width: 103%;
          height: 103%;
          margin: 0;
          position: absolute;
          top: 0;
          left: -10px;
        }
      }

      .partner_contents {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 4.5rem;

        h1 {
          font-size: 38px;
          color: #19b01f;
          font-weight: 900;
          margin: 0;
        }

        p {
          font-size: 20px;
          font-weight: 400;
          color: #2c2c2c;
          margin: 0;
          margin-top: 0.6rem;
          line-height: 1.4;
        }

        button {
          padding: 0.4rem 1.5rem;
          background-color: #19b01f;
          border: none;
          outline: none;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 1.5rem;

          a {
            font-size: 16px;
            color: #fff;
            text-decoration: none;
            font-weight: 600;
          }
          &:hover {
            background-color: #fff;
            box-shadow: 1px 1px 10px #19b01e2f;
            cursor: pointer;

            a {
              color: #19b01f;
            }
          }
        }
      }
    }
  }
}

.commercial_fourth_section_wrapper_mobile {
  display: none;
}


/* Extra small devices (phones, less than 576px) */
@media (max-width: 991.98px) {
  .commercial_fourth_section_wrapper {
    display: none;
  }
  .commercial_fourth_section_wrapper_mobile {
    width: 100%;
    height: max-content;
    background-color: #e7ffeb;
    padding-bottom: 0rem;
    display: block;
  
    .res_fourth_section {
      width: 100%;
      height: max-content;
      padding: 2.5rem 1rem;
      background-color: #fff;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      padding-bottom: 2.5rem;
      padding-top: 1rem;
  
      .featured_products {
        width: 100%;
        height: max-content;
        padding-bottom: 2.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
  
        h1 {
          font-size: 28px;
          font-weight: 900;
          color: #19b01f;
          text-transform: uppercase;
          margin-bottom: 1.5rem;
        }
  
        .product_list {
          width: 100%;
          height: max-content;
          display: flex;
          gap: 1rem;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          margin-bottom: 0;
  
          .product_wrapper {
            width: 250px;
            height: 300px;
            border-radius: 5px;
            position: relative;
  
            .product {
              width: 100%;
              height: 100%;
              border-radius: 5px;
              overflow: hidden;
              position: relative;
              z-index: 2;
  
              img {
                width: 100%;
                height: 100%;
              }
  
              .contents {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 999999999999;
                top: 0;
                left: 0;
                padding: 1.5rem 0 0 1.5rem;
  
                h1 {
                  font-size: 20px;
                  color: #fff;
                  font-weight: 700;
                  margin: 0;
                  margin-bottom: 0.5rem;
                }
  
                p {
                  font-size: 12px;
                  color: #fff;
                  font-weight: 400;
                  margin: 0;
                  margin-bottom: 0.9rem;
                }
  
                button {
                  border: none;
                  outline: none;
                  background-color: #19b01f;
                  border-radius: 5px;
                  padding: 0.3rem 1rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
  
                  a {
                    font-size: 11px;
                    text-decoration: none;
                    color: #fff;
                    font-weight: 700;
                  }
                }
              }
            }
  
            // .overlay {
            //   width: 113%;
            //   height: 75%;
            //   position: absolute;
            //   bottom: -5%;
            //   left: -5%;
            //   background-color: #19b01f;
            //   z-index: 1;
            //   border-top-left-radius: 25px;
            //   border-bottom-left-radius: 15px;
            //   border-bottom-right-radius: 15px;
            // }
          }
        }
      }
  
      .Partner_with_us {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        justify-content: center;
        margin-top: 0;
  
        .partner_image {
          width: 100%;
          min-height: 20rem;
          background-color: orange;
          border-radius: 30px;
          box-shadow: -10px 10px 0 #19b01f;
          padding: 0;
          position: relative;
          overflow: hidden;
          margin: 1.5rem 0;
  
          img {
            width: 103%;
            height: 103%;
            margin: 0;
            position: absolute;
            top: 0;
            left: -10px;
          }
        }
  
        .partner_contents {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-left: 0;
  
          h1 {
            font-size: 28px;
            color: #19b01f;
            font-weight: 900;
            margin: 0;
            text-align: center;
          }
  
          p {
            font-size: 18px;
            font-weight: 400;
            color: #2c2c2c;
            margin: 0;
            margin-top: 0.6rem;
            line-height: 1.4;
            text-align: center;
          }
  
          button {
            padding: 0.4rem 1.5rem;
            background-color: #19b01f;
            border: none;
            outline: none;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1.5rem;
  
            a {
              font-size: 16px;
              color: #fff;
              text-decoration: none;
              font-weight: 600;
            }
            &:hover {
              background-color: #fff;
              box-shadow: 1px 1px 10px #19b01e2f;
              cursor: pointer;
  
              a {
                color: #19b01f;
              }
            }
          }
        }
      }
    }
  }
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* CSS styles here */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* CSS styles here */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* CSS styles here */
}
