* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.res_third_section {
  width: 100%;
  height: 86vh;
  overflow: hidden;
  // border: 5px solid black;

  img {
    width: 100%;
    height: 100%;
  }

  .carousel-indicators {
    button {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 0.5rem;

      &.active {
        background-color: #18af1e !important;
      }
    }
  }
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 675.98px) {
  .res_third_section {
    height: 12rem !important;

    .carousel-indicators {
      margin-bottom: 0;
      button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 0.5rem;

        &.active {
          background-color: #18af1e !important;
        }
      }
    }
  }
}

/* Small devices (phones, 576px and up) */
@media (min-width: 676px) and (max-width: 967.98px) {
  .res_third_section {
    height: 50vh;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 968px) and (max-width: 1199.98px) {
  .res_third_section {
    height: 70vh;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* CSS styles here */
}
