* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: Poppins;
}

.application_area_wrapper {
  width: 100%;
  height: max-content;
}

.application_area_wrapper h3 {
  font-size: 18px;
  font-family: "PoppinsFour";
}

.application_area_wrapper h1 {
  font-size: 28px;
  font-family: "PoppinsThree";

}

.heading_container {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

.Application_Area {
  width: 100%;
  height: 42rem;
  margin: 0;
  /* background-color: #010101; */
  color: #eee;
  overflow: hidden;
  margin-top: 1rem;
}

/* css slider */
.slider {
  height: 100%;
  margin-top: 0px;
  position: relative;
}
.slider .list .item {
  position: absolute;
  inset: 0 0 0 0;
  overflow: hidden;
  opacity: 0;
  transition: 0.5s;
}
.slider .list .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider .list .item::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to top, #0000005d 100%, transparent);
}
.slider .list .item .content {
  position: absolute;
  left: 10%;
  top: 20%;
  width: 500px;
  max-width: 80%;
  z-index: 1;
}
.slider .list .item .content p:nth-child(1) {
  text-transform: uppercase;
  letter-spacing: 10px;
}
.slider .list .item .content h2 {
  font-size: 28px;
  margin: 0;
}
.slider .list .item.active {
  opacity: 1;
  z-index: 10;
}
@keyframes showContent {
  to {
    transform: translateY(0);
    filter: blur(0);
    opacity: 1;
  }
}
.slider .list .item.active p:nth-child(1),
.slider .list .item.active h2,
.slider .list .item.active p:nth-child(3) {
  transform: translateY(30px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent 0.5s 0.7s ease-in-out 1 forwards;
}
.slider .list .item.active h2 {
  animation-delay: 1s;
}
.slider .list .item.active p:nth-child(3) {
  animation-duration: 1.3s;
}
.arrows {
  position: absolute;
  top: 30%;
  right: 50px;
  z-index: 100;
}
.arrows button {
  background-color: #eee5;
  border: none;
  font-family: monospace;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  font-size: x-large;
  color: #eee;
  transition: 0.5s;
}
.arrows button:hover {
  background-color: #eee;
  color: black;
}
.thumbnail {
  position: absolute;
  bottom: 50px;
  z-index: 11;
  display: flex;
  gap: 50px;
  width: 100%;
  height: 150px;
  padding: 0 50px;
  box-sizing: border-box;
  overflow: auto;
  justify-content: center;
  background: transparent;
  /* background-color: #fff; */
}
.thumbnail::-webkit-scrollbar {
  width: 0;
}
.thumbnail .item {
  width: 150px;
  height: 150px;
  filter: brightness(0.5);
  transition: 0.5s;
  flex-shrink: 0;
  border-radius: 10px;
  position: relative;
}

.thumbnail .item:hover {
  cursor: pointer;
}

.thumbnail .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.thumbnail .item.active {
  filter: brightness(1);
}
.thumbnail .item .content {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: auto 10px 10px 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.273);
  border-radius: 10px;
}
.thumbnail .item .content img {
  width: 50px;
  height: 50px;
}
@media screen and (max-width: 678px) {
  .Application_Area {
    height: 35rem !important;
  }
  .thumbnail {
    justify-content: start;
    height: max-content;
    bottom: 10px !important;
    gap: 15px;
  }
  .thumbnail .item {
    width: 120px;
    height: 120px;
    filter: brightness(0.5);
    transition: 0.5s;
    flex-shrink: 0;
  }
  .slider .list .item .content h2 {
    font-size: 28px;
  }
  .arrows {
    top: 10%;
  }
}
