* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.commercial_second_section {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5rem;
  padding-left: 7.5rem;

  .image_wrapper {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .content_container {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7.5rem 0 0rem;

    p {
      font-size: 13px;
      font-weight: 300;
      color: black;
      font-family: "GilroyFive";
      margin: 0;
      margin-bottom: 0.5rem;

      &:nth-child(3) {
        font-weight: 500;
        font-family: "GilroyThree";
        margin-bottom: 1rem;
      }
    }

    h1 {
      font-size: 37.049px;
      font-weight: 900;
      color: #19b01f;
      margin: 0rem 0 1rem 0;
      font-family: "GilroySeven";
    }

    button {
      padding: 0.5rem 2rem;
      border: none;
      outline: none;
      background-color: #19b01f;
      border-radius: 27px;
      margin-top: 0.5rem;
      transition: 0.5s ease;

      a {
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        font-family: "GilroyFour";
      }

      &:hover {
        background-color: #fff;
        box-shadow: 1px 1px 10px #19b01e2f;
        cursor: pointer;

        a {
          color: #19b01f;
        }
      }
    }
  }
}

.commercial_second_section_mobile {
  width: 100%;
  height: 80vh;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1rem;

  .heading_wrapper {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;

    p {
      margin: 0;
      padding: 0;
      text-align: center;
      font-size: 16px;
      color: #000;
      font-family: "GilroyFive";
      margin-bottom: 0.5rem;
    }

    h1 {
      margin: 0;
      padding: 0;
      text-align: center;
      font-family: "GilroyEight";
      color: #19b01f;
      font-size: 32px;
    }
  }

  .image_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 0;

    img {
      width: 100%;
      height: 90%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .content_container {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;

    p {
      font-size: 13px;
      font-weight: 300;
      color: black;
      font-family: "GilroyFive";
      margin: 0;
      margin-bottom: 0.2rem;
      text-align: center;

      &:nth-child(1) {
        font-weight: 500;
        font-family: "GilroyThree";
        margin-bottom: 0.5rem;
      }
    }

    h1 {
      font-size: 28px;
      font-weight: 900;
      color: #19b01f;
      margin: 0rem 0 1rem 0;
      font-family: "GilroySeven";
      text-align: center;
    }

    button {
      padding: 0.3rem 2rem;
      border: none;
      outline: none;
      background-color: #19b01f;
      border-radius: 27px;
      margin-top: 0.5rem;
      transition: 0.5s ease;

      a {
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        font-family: "GilroyFour";
      }

      &:hover {
        background-color: #fff;
        box-shadow: 1px 1px 10px #19b01e2f;
        cursor: pointer;

        a {
          color: #19b01f;
        }
      }
    }
  }

  // .content_container {
  //   width: 100%;
  //   height: max-content;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   padding: 0;

  //   p {
  //     font-size: 16px;
  //     font-weight: 400;
  //     font-weight: 600;
  //     font-family: "GilroyThree";
  //     text-align: center;
  //     margin: 0;
  //   }

  //   button {
  //     padding: 0.5rem 2rem;
  //     border: none;
  //     outline: none;
  //     background-color: #19b01f;
  //     border-radius: 15px;
  //     margin-top: 1rem;
  //     transition: 0.5s ease;

  //     a {
  //       text-decoration: none;
  //       color: #fff;
  //       font-weight: 600;
  //     }

  //     &:hover {
  //       background-color: #fff;
  //       box-shadow: 1px 1px 10px #19b01e2f;
  //       cursor: pointer;

  //       a {
  //         color: #19b01f;
  //       }
  //     }
  //   }
  // }
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 991.98px) {
  .commercial_second_section {
    display: none;
  }

  .commercial_second_section_mobile {
    display: flex;
  }
}

/* Small devices (phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* CSS styles here */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* CSS styles here */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS styles here */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* CSS styles here */
}
