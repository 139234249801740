* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contact_us {
  width: 100%;
  height: max-content;
  background-color: #fff;
  

  .contact_banner {
    width: 100%;
    height: 86vh;
    // background: url("https://www.imgacademy.com/sites/default/files/img-academy-boarding-school-worlds-most-dedicated.jpg");
   
   position: relative;
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;

    // padding-left: 10rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    .about_text_container {
      position: absolute;
      top: 40%;
      left: 10%;
      z-index: 99;
      color: #fff;

      h1 {
        font-size: 48px;
        font-weight: 400;
        margin-bottom: 2rem;
        font-weight: 600;
      }
    }
  }

  .get_in_touch_wrapper {
    width: 100%;
    height: max-content;
    background-color: #fff;
    padding: 5rem;

    h1 {
      font-size: 32px;
      text-align: center;
      color: #000;
      margin-bottom: 2.5rem;
      font-weight: 700;
    }

    .get_in_touch {
      width: 100%;
      height: 19rem;
      // background-color: orange;
      display: flex;
      gap: 1rem;
      align-items: flex-start;
      justify-content: space-between;

      .touch_comp_wrapper {
        width: 25%;
        height: 100%;
        text-align: center;
        padding: 4rem 2.5rem;
        border-radius: 50px;
        color: #2b9b1f;
        transition: 0.5s;

        &:hover {
          background-color: #2b9b1f;
          color: #fff;
        }

        .touch_icon {
          font-size: 48px;
          margin-bottom: 0.5rem;
          font-weight: 400;
        }

        h3 {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 1rem;
        }

        p {
          margin: 0;
          margin-bottom: 0.3rem;
        }
      }
    }
  }

  .contact_form_wrapper {
    width: 100%;
    height: max-content;
    display: flex;
    gap: 2.5rem;
    align-items: flex-start;
    justify-content: center;
    padding: 2.5rem 10rem 7rem 10rem;

    .contact_image {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }
    }

    .contact_form {
      width: 50%;
      height: max-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-right: 2.5rem;

      label {
        margin-bottom: 2px;

        span {
          color: red;
        }
      }

      input,
      textarea {
        width: 100%;
        border: 1px solid #757d8da0;
        padding: 0.5rem;
        outline: none;
        border-radius: 5px;
        margin-bottom: 1.5rem;
      }

      .submit_btn {
        width: 30%;
        background-color: #2b9b1f;
        color: #fff;
        text-transform: uppercase;
        border-radius: 10px;
        font-weight: 600;
        letter-spacing: 0.1rem;
        padding: 0.7rem;
        border: none;
        outline: none;
      }
    }
  }

  .address_map {
    width: 100%;
    height: 25rem;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 575.98px) {
  .contact_us {
    width: 100%;
    height: max-content;
    background-color: #fff;

    .contact_banner {
      width: 100%;
      height: 14rem;
      // background: url("https://www.imgacademy.com/sites/default/files/img-academy-boarding-school-worlds-most-dedicated.jpg");
// background-image: url("Images/contact_us/contact_us.jpg");
      // background-position: center;
      // background-size: cover;
      // background-repeat: no-repeat;
      // display: flex;
      // align-items: center;
      // justify-content: flex-start;
      // padding-left: 2.5rem;
      // padding-right: 5rem;

      .about_text_container {
        color: #fff;

        h1 {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 2rem;
          font-weight: 600;
        }
      }
    }

    .get_in_touch_wrapper {
      width: 100%;
      height: max-content;
      background-color: #fff;
      padding: 2.5rem;

      h1 {
        font-size: 32px;
        text-align: center;
        color: #000;
        margin-bottom: 2.5rem;
        font-weight: 700;
      }

      .get_in_touch {
        width: 100%;
        height: max-content;
        // background-color: orange;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
        justify-content: space-between;

        .touch_comp_wrapper {
          width: 100%;
          height: 100%;
          text-align: center;
          padding: 2.5rem 2.5rem;
          border-radius: 50px;
          color: #2b9b1f;
          transition: 0.5s;
          border: 1px solid #2b9b1f;

          &:hover {
            background-color: #2b9b1f;
            color: #fff;
          }

          .touch_icon {
            font-size: 48px;
            margin-bottom: 0.5rem;
            font-weight: 400;
          }

          h3 {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 1rem;
          }

          p {
            margin: 0;
            margin-bottom: 0.3rem;
          }
        }
      }
    }

    .contact_form_wrapper {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      align-items: center;
      justify-content: center;
      padding: 2.5rem 0rem 7rem 0rem;

      .contact_image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
        }
      }

      .contact_form {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-right: 2.5rem;
        padding-left: 2.5rem;

        label {
          margin-bottom: 2px;

          span {
            color: red;
          }
        }

        input,
        textarea {
          width: 100%;
          border: 1px solid #757d8da0;
          padding: 0.5rem;
          outline: none;
          border-radius: 5px;
          margin-bottom: 1.5rem;
        }

        .submit_btn {
          width: 30%;
          background-color: #2b9b1f;
          color: #fff;
          text-transform: uppercase;
          border-radius: 10px;
          font-weight: 500;
          letter-spacing: 0.1rem;
          padding: 0.7rem;
          border: none;
          outline: none;
          font-size: 14px;
        }
      }
    }

    .address_map {
      width: 100%;
      height: 25rem;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}