* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.commercial_third_section {
    width: 100%;
    height: 86vh;
    overflow: hidden;

     .carousel-indicators {
        button {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 0.5rem;

          &.active {
            background-color: #18af1e !important;
          }
        }
      }


    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}



@media (max-width: 575.98px) {
  .commercial_third_section {
    width: 100%;
    height: 100%;
    overflow: hidden;
    //  border: 3px solid black;
     object-fit: cover;

     .carousel-indicators {
      margin-bottom: 0;
        button {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 0.5rem;

          &.active {
            background-color: #18af1e !important;
          }
        }
      }

      .carousel-inner {
        width: 100%;
        height: 12rem;

        .carousel-item {
          width: 100%;
          height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
      }

}
}