* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contact_dealer_wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, 0.428);
  display: flex;
  align-items: center;
  justify-content: center;

  .contact_dealer {
    width: 75%;
    height: 95%;
    background-color: #fff;
    border-radius: 20px;

    .profile_pic_wrapper {
      width: 100%;
      height: 25%;
      background-color: orange;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      position: relative;

      .cover_pic {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .profile_pic {
        width: 200px;
        height: 200px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(20%, 40%);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
          width: 122%;
        }
      }
    }

    .profile_content_wrapper {
      width: 100%;
      height: 75%;
      // background-color: green;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;

      .content_container {
        width: 50%;
        height: 100%;
        background-color: #fff;
        border-bottom-left-radius: 20px;
        padding: 7.5rem 3rem 0 5rem;

        .about_info {
          width: 100%;
          height: max-content;

          .name,
          .email,
          .phone,
          .address {
            display: flex;
            gap: 0.7rem;
            align-items: center;
            justify-content: flex-start;
            // border: 1px solid green;
            padding: 0;
            margin-bottom: 1rem;

            span {
              font-size: 20px;
              color: #19b01f;
            }

            p {
              margin: 0;
              font-size: 15px;
              font-weight: 600;
            }
          }
        }

        .bottom_header {
            width: 100%;
            height: max-content;
            padding-top: 2rem;
            // padding-right: 3rem;

            h1 {
                font-size: 32px;
                font-weight: 800;

                span {
                    color: #19b01f;
                }
            }
        }
      }

      .form_container_wrapper {
        width: 50%;
        height: 100%;
        // background-color: violet;
        border-bottom-right-radius: 20px;
        position: relative;

        .form_container {
          width: 95%;
          height: 115%;
          background-color: #fff;
          border-radius: 20px;
          position: absolute;
          top: -135px;
          right: 50px;
          box-shadow: 1px 1px 10px #19b01e42;
          padding: 0 2rem;

          .top_heading {
            width: 100%;
            height: max-content;
            font-size: 16px;
            font-weight: 600;
            padding-top: 2rem;
            padding-bottom: 0.5rem;
          }

          .buttons_container {
            width: 100%;
            height: max-content;
            display: flex;
            gap: 0.7rem;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;

            input[type="radio"] {
                display: none;

                &:checked + label {
                    background-color: #19b01f;
                    color: #fff;
                    border: 2px solid #19b01f;
                }
            }

            label {
                border: 2px solid #00000089;
                color: #00000089;
                padding: 0.5rem 1rem;
                border-radius: 5px;

                &:hover {
                    cursor: pointer;
                }
            }
          }

          .info_inputs {
            width: 100%;
            height: max-content;

            input {
                width: 100%;
                margin-top: 5vh;
                padding-top: 0.1rem;
                padding-bottom: 0.1rem;
                outline: none;
                border: none;
                border-bottom: 2px solid #19b01f;
                font-size: 16px;
                color: #000;
            }
          }

          .send_msg_btn {
            margin-top: 5vh;
            padding: 0.7rem 2rem;
            border: none;
            outline: none;
            background-color: #19b01f;
            color: #fff;
            border-radius: 10px;

            .send_msg_icon {
                margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}
