* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.residential_page_second_section {
  width: 100%;
  height: 86vh;
  padding: 2.5rem 0;
//   border: 1px solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 32px;
    font-weight: 900;
    padding-left: 10rem;
    color: #6f6f6f;
    text-align: left;
    width: 100%;
    margin-bottom: 1rem;
  }

  .double_carousel_wrapper {
    width: 80%;
    height: 80%;
    display: flex;
    gap: 2.5rem;
    align-items: center;
    justify-content: center;
    // background-color: orange;

    .left_carousel_wrapper {
        width: 35%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        background-color: #19B01F;
        overflow: hidden;

        img {
            width: 100%;
        }

        .slides {
            width: 100%;
            height: 100%;

            .slide_content_container {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                padding: 2rem 0;

                .icon_container {
                    background-color: #fff;
                    padding: 2rem;
                    border-radius: 50%;
                    box-shadow: 1px 1px 15px #fff;
                    margin-bottom: 1rem;
                }

                .content_container {
                    width: 100%;
                    height: max-content;
                    padding: 0 2.5rem;

                    h1 {
                        text-align: center;
                        margin: 0;
                        padding: 0;
                        color: #fff;
                    }

                    p {
                        margin-top: 1rem;
                        padding: 0;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .right_carousel_wrapper {
      width: 65%;
      height: 100%;
      border-radius: 30px;
      background-color: rebeccapurple;
      box-shadow: 10px 15px 0px #19B01F;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        width: 120%;
      }
    }
  }
}

#carouselExampleCaptions{
  .carousel-indicators {
    // background-color: orange;
    width: 100%;
    height: max-content;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    margin-bottom: 2.5rem;

    button {
      width: 40px;
      height: 40px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 0;
      border: 0.1px solid #fff;
      position: relative;
      background-color: #fff;
      opacity: 1;

      .btn_icons {
        width: 180%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: #19B01F;
      }

      &.active {
        transform: scale(1.1);
        box-shadow: 1px 1px 10px #fff;
      }
    }
  }
}
